import { Injectable, Inject, InjectionToken } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, of, throwError, empty, TimeoutError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from "src/app/services/common.service";
import { validationMessage } from "src/app/constants/validationMessage";
import { environment } from '../../environments/environment';
import { timeout } from 'rxjs/operators';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: "root"
})
export class RequestInterceptor implements HttpInterceptor {
  validationMessage = validationMessage;
  constructor(
    private commonService: CommonService,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.commonService.getToken();
    const DISABLE_ORIGIN_FOR = [
      "/api/v0/tokenrequest",
    ];

    if (token && !DISABLE_ORIGIN_FOR.includes(request.url)) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }
    // if (!request.headers.has('Content-Type')) {
    //     request = request.clone({ headers: request.headers.set('Content-Type', 'application/json;charset=UTF-8') });
    // }

    if (DISABLE_ORIGIN_FOR.includes(request.url)) {
      //request = request.clone({ headers: request.headers.set('Accept', '*/*') });
      //request = request.clone({ headers: request.headers.set( "Access-Control-Allow-Origin", "*" ) });
    } else {
      request = request.clone({ headers: request.headers.set('Accept', '*/*') });
      request = request.clone({ headers: request.headers.set('Access-Control-Allow-Origin', '*') });
    }

    // request = request.clone({ headers: request.headers.set('Connection', 'keep-alive') });
    // request = request.clone({ headers: request.headers.set('Accept-Encoding', 'gzip, deflate, br') });
    // request = request.clone({ headers: request.headers.set('Cache-Control', 'no-cache') });
    // request = request.clone({url: request.url.replace("http://", "https://")});

    const timeoutValue = request.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);

    const modified = request.clone({
      setHeaders: { 'X-Request-Timeout': `${this.defaultTimeout}` }
    });

    return next.handle(modified).pipe(
      timeout(timeoutValueNumeric),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.result == true && event.body.message) {
            this.commonService.notification(this.validationMessage.toasterClass.success, event.body.message);
          }
        }
        return event;
      }),
      catchError((error) => {
        let handled: boolean = false;
        let showMsg: boolean = true;

        if (error instanceof TimeoutError) {
          handled = false;
          this.commonService.notification(this.validationMessage.toasterClass.error, 'Oops! there is some problem on server side, please try after some time.');
          // return empty();
        }

        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            // console.error("Error Event");
          } else {
            // console.log(`error status : ${JSON.stringify(error)} ${error.error}`);
            switch (error.status) {
              case 401:      //unauthorise
                if (error.error) {
                  this.commonService.logout();
                  this.commonService.notification(this.validationMessage.toasterClass.error, error.error.message ? error.error.message : this.validationMessage.common.invalidUser);
                  handled = true;
                  return
                }
                this.commonService.logout();
                this.commonService.notification(this.validationMessage.toasterClass.error, environment.sessionExpireMessage);
                handled = true;
                break;
              case 403:     //forbidden
                this.commonService.logout();
                this.commonService.notification(this.validationMessage.toasterClass.error, this.validationMessage.common.forbiddenAccess);
                handled = true;
                break;
              case 500:     //server error
                this.commonService.notification(this.validationMessage.toasterClass.error, this.validationMessage.common.applicationError);
                handled = false;
                showMsg = false;
                break;
            }
          }
        } else {
          // console.error("Other Errors");
        }

        if (handled) {
          // console.log('return back ');
          return of(error);
        } else {
          if (error.error && error.error.message && showMsg) {
            this.commonService.notification(this.validationMessage.toasterClass.error, error.error.message);
          }
          return throwError(error);
        }
      })
    );
  }
}