import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
import { LoginComponent } from './pages/login/login.component'; 
import { P404Component } from './pages/p404/p404.component';
import { P500Component } from './pages/p500/p500.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AuthGuard } from './guard/auth.guard'
import { LearningCenterComponent } from './pages/learning-center/learning-center.component';
import { BusinessIntelligenceComponent } from './pages/business-intelligence2/business-intelligence/business-intelligence.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {title: 'Login Page'}
  },  
  {
    path: 'reset-password/:username/:tokenNumber',
    component: ResetPasswordComponent,
    data: {title: 'Reset Password'}
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {title: 'Dashboard'}
  }, 
  
  {
    path: 'appointment',
    canActivate: [AuthGuard],
    component: AppointmentComponent,
    data: {title: 'Appointment'}
  }, 

  {
    path: 'learning-center',
    canActivate: [AuthGuard],
    component: LearningCenterComponent,
    data: {title: 'Learning Center'}
  },
  {
    path: "customer-requests", 
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/customer-requests/customer-requests.module').then(m => m.CustomerRequestsModule)
  },

  {
    path: "shipment-info",
    canActivate: [AuthGuard], 
    loadChildren: () => import('./pages/outbound/outbound.module').then(m => m.OutboundModule)
  },
  {
    path: "administration",
    canActivate: [AuthGuard], 
    loadChildren: () => import('./pages/administration/administration.module').then(m => m.AdministrationModule)
  },
  {
    path: "alerts-notifications", 
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/alerts-notifications/alerts-notifications.module').then(m => m.AlertNotificationsModule)
  },
  // {
  //   path: "business-intelligence", 
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./pages/business-intelligence/business-intelligence.module').then(m => m.BusinessIntelligenceModule)
  // },
  {
    path: "invoices",
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/invoices/invoices.module').then(m => m.InvoicesModule)
  },
  {
    path: "business-intelligence",
    canActivate: [AuthGuard],
    component: BusinessIntelligenceComponent,
    data: {title: 'Business Intelligence'}
  },
  {
    path: "online-order", 
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/online-order/online-order.module').then(m => m.OnlineOrderModule)
  },
  {
    path: "inventory",
    canActivate: [AuthGuard], 
    loadChildren: () => import('./pages/inventory/inventory.module').then(m => m.InventoryModule)
  },
  {
    path: "receipts",  
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/receipts/receipts.module').then(m => m.ReceiptsModule)
  },  
  {
    path: "user", 
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'unauthorise',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'error',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: '**',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
