import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router,NavigationEnd } from '@angular/router';
import { validationMessage } from 'src/app/constants/validationMessage';
declare var $: any;

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  userData:any;
  sideMenu:any;
  showSideMenu:boolean=false;
  slug:string='';
  @Output() onChange = new EventEmitter<any>();
  validationMessage = validationMessage;

  constructor( public router: Router,
    public titleService:Title) {
    router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {       
        let currentUrl=router.url;
        
        let currentUrlArr=currentUrl.split('/');
        let mainpath=currentUrlArr[1];  
         
        this.slug = currentUrl.slice(1); 
        
        this.userData = JSON.parse(localStorage.getItem('ArcUser'));
       
        
        
        if(this.userData && this.userData.menu){
          let ress = this.userData.menu.filter((menuItem) => { 
            return (menuItem.menuURL.includes(mainpath) && menuItem.reportMenu != null ) ? true : false;
          });
          
          if(ress.length > 0){
            this.showSideMenu = true;
            this.sideMenu = ress; 
            let title = ress[0].reportMenu.filter((item)=>item.reportURL == this.slug).map(i => i.reporttitlename)
            if(title.length>0){ 
            this.onChange.emit(title[0])
            this.titleService.setTitle(validationMessage.common.heading+ title)}
          }
          else{
           
            this.showSideMenu = false;
            this.sideMenu = [];
            
          }  

        } 
      }    
    }); 
  }

  ngOnInit(): void {  
  }

  toggleClassOnBody(){
    $("body").toggleClass("sidebar-icon-only");   
  }
}
