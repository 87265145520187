<div class="{{mainClass}}">
    <app-confirm-dialog></app-confirm-dialog>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
    <div [ngClass]="urlPath ? '' : 'main-holder'"> 
        <app-header class="header" *ngIf="showheader"></app-header> 
        <router-outlet></router-outlet>
    </div> 
    <app-footer class="footer" *ngIf="showFooter"></app-footer>
</div>