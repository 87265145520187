import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
 
@Component({
    selector: 'datepicker',
   template: `<input placeholder="MM/DD/YYYY" [id]="field.filterLabel"  [owlDateTimeTrigger]="toD" [owlDateTime]="toD" class="textbox-fullsize" [formControl]="form" [(ngModel)]="defaultDate">
<owl-date-time  [pickerType]="'calendar'" #toD></owl-date-time>`

})
export class DatePickerComponent { 
    @Input() field:any = {};
    @Input() form:FormControl;
    defaultDate : Date;
    get isValid() { return this.form.valid; }
    get isDirty() { return this.form.dirty; } 

    constructor() { }

    ngOnInit(){   
        this.defaultDate = new Date();
        let dat = this.defaultDate.setDate(this.defaultDate.getDate()-1);
        this.form.patchValue( dat.toLocaleString() ) 
    }
}