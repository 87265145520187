<div class="card123">
    <div class="row m-0">
        <div class="col-md-12">
            <h2 class="d1">{{ drillDownTitle ? drillDownTitle : reportTitle}}</h2>
        </div>
        <div class="col-md-12">
            {{ drillDownPayload }}
        </div>
    </div>

    <div class="row m-0">
        <ngx-spinner name="FormLoader" [fullScreen]="false" type="timer" size="medium"></ngx-spinner>

        <div class="widthFullBlk" *ngIf="isFormControlsReady && isMainReport">
            <form [formGroup]="form">
                <dynamic-form-builder [formGroup]="form" [fields]="group.fields"
                    (onChange)="onChangeDropdown($event)"></dynamic-form-builder>
                <div class="row">
                    <div class="col-md-12 mb-3" *ngIf="showDateControls">
                        <div class="col-md-4">
                            <h5> Date Range <strong class="text-danger" *ngIf="!selectDateRange">*</strong></h5>
                            <ng-select [clearable]="false" [items]="timeRange" bindValue="id" bindLabel="label"
                                placeholder="Select" (change)="setToFromDates($event)" [(ngModel)]="selectDateRange"
                                formControlName="dateRange"></ng-select>
                        </div>
                        <div class="col-md-4">
                            <h5>From <strong class="text-danger"
                                    *ngIf="!SrchDateFrom || showDateControls == null">*</strong></h5>
                            <input [disabled]="dateControlsDisabled" placeholder="MM/DD/YYYY" [(ngModel)]="SrchDateFrom"
                                [owlDateTimeTrigger]="fromD" [owlDateTime]="fromD" class="textbox-fullsize"
                                formControlName="SrchDateFrom" [readOnly]="dateControlsDisabled" id="SrchDateFrom">
                            <owl-date-time [disabled]="dateControlsDisabled" [pickerType]="'calendar'"
                                #fromD></owl-date-time>
                        </div>
                        <div class="col-md-4">
                            <h5>To <strong class="text-danger" *ngIf="!SrchDateTo || SrchDateTo == null">*</strong></h5>
                            <input [disabled]="dateControlsDisabled" placeholder="MM/DD/YYYY" [(ngModel)]="SrchDateTo"
                                [owlDateTimeTrigger]="toD" [owlDateTime]="toD" class="textbox-fullsize"
                                formControlName="SrchDateTo" [readOnly]="dateControlsDisabled" id="SrchDateTo">
                            <owl-date-time [disabled]="dateControlsDisabled" [pickerType]="'calendar'"
                                #toD></owl-date-time>
                        </div>
                    </div>
                </div>

                <div class="">
                    <div class="col-md-12 text-center">
                        <!-- [disabled]="!form.valid"  -->
                        <input (click)="getReport()" type="submit" value="Search" class="submit-button" />
                        <input (click)="resetForm()" type="button" value="Reset" class="submit-button" />
                        <span *ngIf="userData.userCompanyInfo[0].userType != 'Arcadia Staff'">
                            <input *ngIf="showScheduleButton === true" (click)="scheduleReportNotification()" type="button"
                                [value]="isEdit ? 'Update Schedule' : 'Save As Schedule'" class="submit-button" />
                        </span>
                    </div>
                </div>
            </form>
        </div>

        <!-- Report -->
        <div class="col-md-12 mt-3 mb-3 text-md-right text-xs-start">
            <div class="driverNameWithButtons">
                <div class="driverNameWithCheck">
                    <!-- Checkbox with Text -->
                    <ul class="listing_blk" *ngIf="reportTableData.data.length && reportId === 'OUTBR021.00'">
                        <li>
                            <label class="form-check-label">
                                <input type="checkbox" [(ngModel)]="isRegenerate" class="form-check-input_blk">
                                <h5 style="margin-left:6px;" class="checkbox-text">Regenerate</h5>
                            </label>
                        </li>
                    </ul>

                    <div class="align-items-center" style="margin-left:60px;" *ngIf="drivernamerequired">
                        <h5 class="col-form-label" style="margin-left:20px; margin-right:6px;">Driver Name</h5>
                        <div class="inputBox">
                            <input type="text" class="textbox-fullsize" [(ngModel)]="drivername"
                                [ngClass]="{'is-invalid': reflectError}" (keyup)="getDriverValue($event)">
                        </div>
                    </div>
                </div>


                <div class="allButtonsBlk">
                    <button class="white-button" *ngIf="reportTableData.data.length && reportId == 'OUTBR021.00' "
                        (click)="StartSignPrint()"><i class="fas fa-print"></i> Print Ticket</button>
                    <button class="white-button" *ngIf="reportTableData.data.length && reportId == 'OUTBR022.00' "
                        (click)="printPackingList()"><i class="fas fa-print"></i> Print Packing List</button>
                    <button class="white-button" *ngIf="reportTableData.data.length && reportId == 'RCPTR059.00' "
                        (click)="printDocument()"><i class="fas fa-print"></i> Print Document</button>
                    <button class="white-button" *ngIf="reportTableData.data.length && reportId == 'OUTBR060.00' "
                        (click)="StartSignPrintMasterBOL()"><i class="fas fa-print"></i> Print Master BOL</button>

                    <button class="white-button" *ngIf="!shouldDisplayButtons() && reportTableData.data.length"
                        (click)="emailReport()">
                        <i class="fas fa-envelope"></i> Email
                    </button>

                    <button class="white-button mt-xs-1" *ngIf="!shouldDisplayButtons() && reportTableData.data.length"
                        (click)="exportReport('excel')">
                        <i class="fas fa-file-excel"></i> Export to Excel
                    </button>

                    <!-- <button class="white-button mt-xs-1" *ngIf="!shouldDisplayButtons() && reportTableData.data.length"
                        (click)="exportexcel1()">
                        <i class="fas fa-file-excel"></i> Excel1
                    </button> -->

                    <button class="white-button mt-xs-1" *ngIf="!shouldDisplayButtons() && reportTableData.data.length"
                        (click)="exportReport('pdf')">
                        <i class="fas fa-file-pdf"></i> Export to PDF
                    </button>

                    <button class="white-button mt-xs-1" *ngIf="shouldDisplayButtons() && reportTableData.data.length"
                        (click)="openUploadPopup(uploadDocument)">
                        <i class="fas fa-file-pdf"></i> Upload
                    </button>

                    <button class="white-button mt-xs-1" *ngIf="shouldDisplayButtons() && reportTableData.data.length"
                        (click)="openDownloadPopup(downloadDocument)">
                        <i class="fas fa-file-pdf"></i> Download
                    </button>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="autoScrollBlk">
                <div class="table-area" style="overflow-x:auto;" *ngIf="reportTableHeader.length">
                    <div class="example-list"><span *ngIf="groupColumn?.length==0"><mat-icon>drag_indicator</mat-icon> Drag a column header and drop it here to group by that column.</span>
                        <ng-container *ngFor="let item of groupColumn">
                          <div class="example-box white-button">{{item}}<mat-icon (click)="removeitem(item)">close</mat-icon></div>
                        </ng-container>
                    </div>
                    <mat-table [dataSource]="reportTableData"  cellpadding="0" matSort 
                    (matSortChange)="sortData($event)" cellspacing="0" id="header-fixed" cdkDropList (cdkDropListDropped)="drop($event)" width="100%"
                        overflow="scroll" class="data-table mb-0 mt-3 mb-2">
                        <ng-container matColumnDef="checkbox"
                            *ngIf="(reportId == 'OUTBR021.00' || reportId == 'OUTBR022.00' || reportId == 'RCPTR059.00' || reportId == 'OUTBR060.00' || reportId == 'RCPTR068.00' || reportId == 'OUTBR069.00') && isMainReport">
                            <th mat-header-cell *matHeaderCellDef>
                                <input type="checkbox" [checked]="isAllCheckBoxChecked()"
                                    (change)="checkAllCheckBox($event)" />
                            </th>
                            <td mat-cell *matCellDef="let element"><input *ngIf="reportTableData.data[i]['Client ID']"
                                    type="checkbox" [(ngModel)]="reportTableData.data[i].checked"
                                    (change)="checkAndSetFlags()" /> </td>
                        </ng-container>
                        <ng-container *ngFor="let header of reportTableHeader   | paginate
                        : {
                            itemsPerPage: itemsPerPage,
                            currentPage: page,
                            totalItems: totalItems
                          }; let i = index"
                            matColumnDef="{{ header.columnName }}">
                            <mat-header-cell class="matHeader" mat-sort-header *matHeaderCellDef [ngStyle]="{'text-align': header.columnAlignment}" [cdkDragData]="header" cdkDrag>{{ header.columnName }}
        
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" [ngClass]="{'bold-tr' : row.isHighlighted == '1'}">{{ row[header.columnName] }}</mat-cell>
        
                        </ng-container>
        
                        <mat-header-row mat-header-row *matHeaderRowDef="reportTableHeaderColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: reportTableHeaderColumns;"></mat-row>
                        
        
                        <!-- Group header -->
                        <ng-container matColumnDef="groupHeader">
                            <mat-cell colspan="999" *matCellDef="let group">
                                <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
                                <mat-icon *ngIf="!group.expanded">expand_more</mat-icon>
                                <strong>{{groupByColumns[group.level-1]}} = {{group[groupByColumns[group.level-1]]}}
                                    ({{group.totalCounts}})</strong>
                            </mat-cell>
                        </ng-container>          
        
                        <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
                            (click)="groupHeaderClick(row); getDataRowVisible(row)"> </mat-row>
        
                    </mat-table>
                    <p *ngIf="noDataFlag" style="text-align: center;" class="mt-3">No data found!</p>
                    <pagination-controls *ngIf="reportTableData.data.length" class="pagi mt-3"
                        (pageChange)="funPagination(( page = $event))"></pagination-controls>
        
                    <!-- Print Table -->
                    <!-- <table *ngIf="reportTableHeaderExport.length && isExportingReport" cellpadding="0" cellspacing="0" id="exportReportTable" width="100%" overflow="scroll" class="scrollTable data-table mb-0 mt-3 mb-2" style="opacity: -100;">
                    <thead>
                        <tr>
                            <th *ngIf="reportId == 'OUTBR021.00' && isMainReport"><input type="checkbox"  [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"/></th>
                            <th *ngFor="let header of reportTableHeaderExport; let i=index" [ngStyle]="{'text-align': header.columnAlignment}">{{header.columnName}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of reportTableDataExport; let i=index" [ngClass]="{'bold-tr' : row.isHighlighted == '1'}">
                            <td *ngIf="reportId == 'OUTBR021.00' && isMainReport">
                                <input *ngIf="reportTableDataExport[i]['Client ID']" type="checkbox" [(ngModel)]="reportTableData.data[i].checked" (change)="checkAndSetFlags()"/>
                            </td>
                            
                            <td *ngFor="let header of reportTableHeaderExport; let j=index" [ngStyle]="{'text-align': header.columnAlignment}">
                                <div>{{ row[header.columnName] }}</div>
                            </td>
                        </tr>
                        <tr *ngIf="!reportTableHeaderExport.length">
                            <td colspan="reportTableHeaderExport.length">No record found!</td>
                        </tr>
                    </tbody>
                </table> -->
                    <!-- Print Table -->
        
                </div>
                <ng-template #linksPopup let-modal>
                    <ngx-spinner name="dataTableLoader" [fullScreen]="false" type="timer" size="medium"></ngx-spinner>
        
                    <div class="modal-header helpBg">
                        <h6 class="modal-title">{{modelHeading}}</h6>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body mt-0" style="overflow:auto">
                        <div *ngIf="currentPopupField == 'Download(s)'">
                            <div *ngFor="let selected of modelText; let index = index">
                                <span class="title-name1 title">{{ selected.split("/").pop() }}</span> &nbsp; <button
                                    type="button" class="btn btn-primary btn-sm download-btn" title="Download"
                                    (click)="downLoadFile(selected)"><i class="fa fa-download"
                                        aria-hidden="true"></i></button>
                            </div>
                        </div>
                        <div *ngIf="currentPopupField != 'Download(s)'" [innerHTML]="modelText | noSanitize"></div>
                    </div>
                </ng-template>
            </div>
        </div>

        <div class="col-md-12 mt-3 text-right" *ngIf="isDrillDownReport">
            <button (click)="goBackReport()" type="button" class="submit-button mr-2 btn btn-primary btn-md"><i
                    class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
        </div>



        <!-- EMAIL REPORT POPUP -->
        <ng-template #EmailReportPopup let-modal>
            <div class="modal-header pb-0" style="border-bottom:0;">
                <h6 class="modal-title">Email Report ({{isDrillDownReport? drillDownTitle : reportTitle}})</h6>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <form [formGroup]="emailReportForm">
                <ngx-spinner name="popupForm" [fullScreen]="false" type="timer" size="medium"></ngx-spinner>

                <div class="modal-body mt-0">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                        <label class="mb-1">Email : <span class="error"
                                *ngIf="emailReportForm.controls.emails.errors">*</span></label>
                        <ng-select [search]="true" [clearable]="true" [closeOnSelect]="false" [multiple]="true"
                            [defaultValue]="" [items]="allUserEmails" bindValue="dropDownValue" bindLabel="dropDownText"
                            placeholder="Select" (change)="setEmailCounter($event)" formControlName="emails">
                            <ng-template ng-header-tmp>
                                <div>
                                    <button class="btn btn-link" (click)="onSelectAll()">Select All</button>
                                    <button class="btn btn-link" (click)="onClearAll()">Clear All</button>
                                </div>

                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                {{item.dropDownText}}
                            </ng-template>

                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div *ngIf="selectedEmailCounter  == 1" class="ng-values-list">
                                    <div class="ng-value" *ngFor="let item of items | slice:0:2">
                                        <span class="ng-value-label">{{item.dropDownText}}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)">×</span>
                                    </div>
                                </div>
                                <div *ngIf="selectedEmailCounter > 1" class="ng-summary-list">
                                    <div class="ng-value">
                                        <span class="ng-value-label">{{ selectedEmailCounter }} Selected</span>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-12 col-lg-12 mb-3">
                        <label class="mb-1">Comment: <span class="error"
                                *ngIf="emailReportForm.controls.comment.errors">*</span></label>
                        <textarea cols="" formControlName="comment" rows="3" class="textbox-fullsize"
                            placeholder="Additional Comment"></textarea>
                    </div>
                </div>
                <div class="modal-footer pt-0" style="border-top:0;">
                    <button class="white-button" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="submit-button widthAuto" (click)="submitReportEmail()">Send</button>
                </div>
            </form>
        </ng-template>
    </div>
</div>


<!-- popup upload window -->
<ng-template #uploadDocument let-modal>
    <ngx-spinner name="dataTableLoader" [fullScreen]="false" type="timer" size="medium"></ngx-spinner>
    <div class="modal-header helpBg">
        <h6 class="modal-title">Upload(s)</h6>
        <button type="button" class="close" aria-label="Close" (click)="onCancelClick()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body ">
        <div class="row">
            <div class="col-md-6">
                <ul class="filesData">
                    <li *ngFor="let item of responseDataDisplay | keyvalue">
                        <div class="fileName">{{ item.key }}</div>
                        <ng-container>
                            <div class="btnBlk" *ngFor="let fitem of item.value">
                                <div class="attach_file_blk12">
                                    <div class="btnSmBlk">
                                        <div *ngIf="fitem.fileName !=''" class="title">{{ fitem.fileName }} <div
                                                (click)="deleteUploadFiles(fitem)"><i class="fas fa-times"></i></div>
                                        </div>
                                    </div>
                                </div>
                                <p *ngIf="fitem.fileName ==''" class="title mt-2 mb-2">No file available for this order.
                                </p>
                            </div>
                        </ng-container>
                    </li>
                </ul>
            </div>

            <div class=" col-xs-12 col-sm-12 col-md-6 col-lg-5 offset-lg-1 mb-0 text-right">
                <ul class="filesDataList">
                    <li *ngFor="let selected of listOfFiles; let index = index">
                        <div class="attach_file_blk" style="cursor: pointer">
                            <div class="title">{{ selected.name }}</div>
                            <button mat-icon-button class="delete_blk" (click)="removeSelectedFile(index)">
                                <i class="fas fa-times-circle"></i>
                            </button>
                        </div>
                    </li>
                </ul>
                <button (click)="attachmentInput.click()" class="button white-button"><i
                        class="fas fa-cloud-upload-alt"></i> Attach files</button>
                <input style="display: none" #attachmentInput type="file" (change)="onFileChanged($event)"
                    multiple="true" />
            </div>
            <div class="col-md-12 text-right mt-5">
                <button class="button white-button mr-2" (click)="onCancelClick()">Close</button>
                <button class="submit-button widthAuto float-none mr-0" (click)="submitUpload()">Save</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #downloadDocument let-modal>
    <ngx-spinner name="dataTableLoader" [fullScreen]="false" type="timer" size="medium"></ngx-spinner>
    <div class="modal-header helpBg">
        <h6 class="modal-title">Download(s)</h6>
        <button type="button" class="close" aria-label="Close" (click)="onCancelClick()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body ">
        <div class="row">
            <div class="col-md-12">
                <div class="dataListBlk" *ngFor="let item of downloadDisplay | keyvalue">
                    <h6 class="title mb-2">{{ item.key }}</h6>
                    <ul class="downBlk">
                        <li *ngFor="let litem of item.value">
                            <ng-container *ngIf="!isFileNameEmpty(litem.fileName)">
                                <div class="attach_file_blk newDown" style="cursor: pointer">
                                    <div class="title">{{ litem.fileName }}</div>
                                    <!-- <a [href]="item.filePath" download="{{ getFileNameFromPath(litem.filePath) }}"
                                        rel="noopener noreferrer">
                                        <button mat-icon-button class="white-button">
                                            <i class="fas fa-download"></i>
                                        </button>
                                    </a> -->
                                    <button mat-icon-button class="white-button"
                                        (click)="downLoadFileForReport(litem.filePath, litem.fileName)">
                                        <i class="fas fa-download"></i>
                                    </button>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="isFileNameEmpty(litem.fileName)">
                                <div>
                                    <p class="title mt-4">No file available for this order.</p>
                                </div>
                            </ng-container>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-12 text-right mt-5">
                <button class="button white-button mr-0" (click)="onCancelClick()">Close</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #deleteconfirmation let-modal>
    <div class="modal-body bgRed modalBorder">
        <h5 class="text-center mb-4">Are you sure you want to delete this file?</h5>
        <p class="confirm-button">
            <button class="btn btn-success widthAuto" (click)="modal.close('Yes')">Yes</button>&nbsp;
            <button class="btn btn-danger" (click)="modal.dismiss('No')">No</button>
        </p>
    </div>
</ng-template>