// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false, 
  frontendUrl: 'https://portal.arcadiaone.com/',
  backendUrl: 'https://api.arcadiaone.com/', 
  sessionExpireInMinutes: 45,
  sessionExpireMessage: "Session Expired: Due to 45 minutes of inactivity, you've been logged out. Please log in again to continue.",
  signalrPath: 'https://api.arcadiaone.com/signalr',
  httpRequestTimeout: 6000000
}; 
