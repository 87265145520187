export const regExConstant ={
    "email": (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    "onlyNumber": (/^[0-9]*$/),
    "mobileNumber": (/^[0][6-9]\d{9}$|^[6-9]\d{9}$/),
    "tenDigitsOnly": (/^\d{10}$/),
    "onlyTwoDecimals": (/^[0-9]+(\.[0-9]{1,2})?$/),
    "onlyFourDecimals": (/^-?[0-9]+(\.[0-9]{1,4})?$/),
    "onlyAlphaNumeric": (/^[a-zA-Z0-9]*$/),
    "defaultCharacter": (/^[a-zA-Z0-9_@!#$%&*+-/, ]*$/),
    "alphabetsOnly": (/^[a-zA-Z ]*$/),
    "floatNumberOnly": (/^[.0-9]*$/),
    "defaultNumbers": (/^[0-9_.,% ]*$/),
    "password":(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),
    "latitude": (/^(\+|-)?((\d((\.)|\.\d{1,6})?)|(0*?[0-8]\d((\.)|\.\d{1,6})?)|(0*?90((\.)|\.0{1,6})?))$/),
    "longitude": (/^(\+|-)?((\d((\.)|\.\d{1,6})?)|(0*?\d\d((\.)|\.\d{1,6})?)|(0*?1[0-7]\d((\.)|\.\d{1,6})?)|(0*?180((\.)|\.0{1,6})?))$/),
    "signedNumbers": (/^[0-9-.]*$/),
    "negativeNumbers": (/^\-?(\d+\.?\d*|\d*\.?\d+)$/),
    "stringsAlphaNumeric": (/^[a-zA-Z0-9-_ ]*$/),
    
    "onlyIntegerNumber": (/^[0-9]\d*$/),
    "nameValidators": (/^[a-zA-Z0-9_#&. \- ]*$/), 
    "descriptionValidator": (/^[a-zA-Z0-9_#'&." \- ]*$/),
    "addressValidators": (/^[a-zA-Z0-9#',. \- ]*$/),
    "passwordValidators": ('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!*&#])[A-Za-z\d$@!*&#].{11,30}'),
    "newDescriptionValidator": (/^[a-zA-Z0-9_~`'.,"!@$^*(){}|<>?+=:[\]₹; \- ]*$/),
    "alertEmail": (/^(?!.*[._]{2})(?!.*[.-]{2})(?!.*--)[a-zA-Z0-9-]+(?:[._][a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(?:[.-][a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}(?:,\s*(?!,)(?!.*[._]{2})(?!.*[.-]{2})(?!.*--)[a-zA-Z0-9-]+(?:[._][a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(?:[.-][a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})*$/)

}


