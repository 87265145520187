import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private permissions = new BehaviorSubject<any>({});
  globalPermissions = this.permissions.asObservable();

  private configStatus = new BehaviorSubject<any>({});
  globalConfigStatus = this.configStatus.asObservable();


  private globalnotificationcount = new BehaviorSubject<any>({});
  __notificationcount = this.globalnotificationcount.asObservable();


  private allglobalnotificationcount = new BehaviorSubject<any>({});
  __allnotificationcount = this.allglobalnotificationcount.asObservable();

  constructor() { }

  sharePermissionData(permissionData){
    this.permissions.next(permissionData);
  }

  shareConfigStatus(status){
    this.configStatus.next(status);
  }

  NotificationCount(p) {
    this.globalnotificationcount.next(p)
  }

  allNotificationCount(p) {
    this.allglobalnotificationcount.next(p)
  }

  shareItemId(){
    
  }


}
