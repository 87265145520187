<div class="clear"></div>
    <div class="container-fluid p-0"> 
        <div class="col-md-12 mb-4">
            <div class="row"> 
                <div class="col-md-12 mt-3">
                    <div class="page404 box-dash pb-3">
                        <div class="col-md-12"> 
                            <div class="section text-center">
                                <h1 class="error404">500</h1>
                                <h3 class="title404 mb-3">Internal server error</h3>
                                <p class="text-center txt20">We are currently trying to fix the problem.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>
