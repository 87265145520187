import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'dynamic-form-builder',
  template:`
    <form [formGroup]="formGroup" class="form-horizontal searchTemplateForm ">
    <div class="row">
    <div class="col-md-12 d-flex" style="flex-wrap: wrap;">
      <div *ngFor="let field of fields" class="searchTemplateFormFields col-md-4">
          <field-builder  (onChange)="callSomeFunction($event)"  [field]="field" [form]="formGroup"></field-builder>
      </div> 
      </div>
      </div>
    </form>
  `,
})
export class DynamicFormBuilderComponent implements OnInit {
  @Input() fields: any[] = [];
  @Input() formGroup: FormGroup;
  @Output() onChange = new EventEmitter<any>();

  constructor() {  }
  ngOnInit(){  }

  callSomeFunction($event){ 
    this.onChange.emit($event);  
  }   
}
