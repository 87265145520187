import { Component, OnInit, Renderer2 } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Router, NavigationEnd } from '@angular/router';
import { navigationConstants } from "src/app/constants/navigationConstant";
import { ApiserviceService } from 'src/app/services/apiservice.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { validationMessage } from "src/app/constants/validationMessage";
import { APIConstant } from "src/app/constants/apiConstants";
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { customStorage } from "src/app/constants/storageKeys"; 
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/services/shared.service';
import 'src/assets/js/jquery-simple-mobilemenu.js'; 
import * as _ from 'lodash' 
import * as signalR from '@microsoft/signalr';
declare var $: any;

import { SignalrService } from 'src/app/services/signalr.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  environment = environment
  validationMessage = validationMessage;
  thickMenu: boolean = true;
  isShow: boolean = true;
  mainMenu: any;
  selectedAccountsDisplay: any = [];
  selectedAccountsString: string = '';
  selectedAccountCount: number
  mainMenuSlug: string = '';
  closeResult: string;
  anyAccountSelect: boolean = true;
  btnAccountSelectionDisabled: boolean = false;
  countNotification: number = 0;
  userData: any;
  pendingPatient: any;
  pendingPatientCount: 0;
  pendingPatientCountNew: 0;
  ipAddress: string = '14.97.189.106'
  activeclass: boolean = false
  isSameWarehouselist:boolean=false
  sameWarehouseName:any

  reportmenu: any = [];
  Data: any = []
  uatInstance: boolean = false

  constructor(
    private renderer: Renderer2,
    private commonService: CommonService,
    private apiserviceService: ApiserviceService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private signalRService: SignalrService
  ) {
    this.userData = JSON.parse(localStorage.getItem('ArcUser'));

    this.mainMenuSlug = router.url.split('/')[1]
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        let currentUrl = router.url.split('/');
        this.mainMenuSlug = currentUrl.length > 0 ? currentUrl[1] : '';
      }
    });

    // this.signalRService.initializeSignalRConnection()
  }

  ngOnInit(): void {
    if(environment.frontendUrl == 'https://testportal.arcadiaone.com/') {
      this.uatInstance = true
    }

    this.getNotificationCount();

    this.sharedService.__allnotificationcount.subscribe(res => {
        this.countNotification = res
    }) 

    this.sharedService.__notificationcount.subscribe(res => {
      if(res == 1) {
        this.countNotification -= 1;
      }
    })   

    // const connection = new signalR.HubConnectionBuilder()
    //   .configureLogging(signalR.LogLevel.Information)
    //   .withUrl(environment.signalrPath) 
    //   .build();

    // connection.start().then(function () {
    //   console.log('SignalR Connected!');
    // }).catch(function (err) {
    //   return console.error(err.toString());
    // });

    // connection.on("BroadcastMessage", () => {
    //   console.log('BroadcastMessage')
    //   this.getNotificationCount();
    // });

    this.simpleMenu()

    if (!this.userData.selectedAccounts) {
      this.userData.selectedAccounts = [];
    }

    let InCaseOfOne: any = [];
    if (this.userData.selectedAccounts.length == 1) {
      let tempArr = this.userData.selectedAccounts[0].split('@');
      let tempArr2 = tempArr[0].split(',');
      if (tempArr2.length > 1) {
        InCaseOfOne = this.userData.selectedAccounts;
      }
    }

    console.log(this.userData.selectedAccounts)
    //this.selectedAccountsDisplay = (this.userData.selectedAccounts.length >1 ) ? this.userData.selectedAccounts.slice(0,2) : InCaseOfOne;
    this.selectedAccountsDisplay = (this.userData.selectedAccounts.length) ? this.userData.selectedAccounts.slice(0, 2) : [];
    console.log(this.selectedAccountsDisplay)
    
    this.multipleAccountForSameWarehouse()
    console.log(this.selectedAccountsDisplay)
    
   this.selectedAccountsString = this.userData.selectedAccounts.join(' | ');
    this.selectedAccountCount = this.userData.selectedAccounts.length;

  }

  multipleAccountForSameWarehouse(){
    if(this.selectedAccountsDisplay[0].split(',').length>3){
      this.isSameWarehouselist=true
      
      this.selectedAccountsDisplay.forEach((element)=>{
      this.sameWarehouseName=element.split('@')[1]
        //console.log(this.sameWarehouseName)
      this.selectedAccountsDisplay=element.split(',').slice(0,2)
      //console.log(this.selectedAccountsDisplay) 
        
      }
      )
      
    }
    
    

  }

  

  getNotificationCount() {
    this.apiserviceService.get(APIConstant.GetPortalAlertCount, { UserName: this.userData.username }).subscribe(
      (response) => {
        this.countNotification = parseInt(response.data.portalAlertCount)
      },
      error => {
        // console.log("error", error)
      })
  }

  /** mobile menu start */
  addActiveClass(menuSectionName) {
    this.Data = this.userData.menu
    this.reportmenu = this.Data.find(element => {
      return element.menuSectionName == menuSectionName
    })
  }

  simpleMenu() {
    $(document).ready(function() {
      $(".mobile_menu").simpleMobileMenu({
        "hamburgerId" : "sm_menu_ham", 
        "wrapperClass" : "sm_menu_outer", 
        "submenuClass" : "submenu",
        "menuStyle" : "slide",
        "onMenuLoad" : function(menu) {
          //  console.log(menu)
         },
         //Callback - menu toggle(open/close)
         "onMenuToggle" : function(menu,open) {
          //  console.log(open)
          }
       });
    })
  }
  /** mobile menu end */

  logout() {
    let payload = {
      "userName": this.userData.username,
      "ipAddress": '' 
    }
    this.apiserviceService.post(APIConstant.insertUserLogOutHistory, payload, {}).subscribe((response) => {
      if (response.result) {
        this.commonService.logout();
      }
    }, error => {
      let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong!'
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg);
    })
  }

  openAccountSelectionPopup(modelContent) {
    this.modalService.open(modelContent, {
      size: "md lg modal-dialog-centered",
      backdrop: 'static',
      windowClass: "modalClass-700",
      keyboard: false,
      ariaLabelledBy: "modal-basic-title"
    }).result.then(result => {
      this.closeResult = `Closed with: ${result}`;
    }, reason => {
      this.closeResult = `Dismissed`;
    }
    );
  }

  checkAllCheckBox(ev: any) {
    this.userData.allAccounts.forEach(x => x.checked = ev.target.checked)
    this.checkAndSetFlags();
  }
  isAllCheckBoxChecked() {
    return this.userData.allAccounts.every(p => p.checked);
  }

  checkAndSetFlags() {
    const isAnySelected = (this.userData.allAccounts.find(x => x.checked === true) ? true : false)

    if (isAnySelected) {
      this.anyAccountSelect = true;
      this.btnAccountSelectionDisabled = false;
    } else {
      this.anyAccountSelect = false;
      this.btnAccountSelectionDisabled = true;
    }
  }

  accountSelectionSubmit() {
    if (!this.anyAccountSelect) {
      this.commonService.notification(this.validationMessage.toasterClass.info, 'You have to select atleast one account');
      return;
    }
    const selectedAccountsTemp = (this.userData.allAccounts.filter(x => x.checked === true));
    const groupedAccounts = _.groupBy(selectedAccountsTemp, acc => acc.wh_name);

    const selectedAccounts = [];
    Object.keys(groupedAccounts).forEach(key => {
      const custIds = groupedAccounts[key].map((item) => {
        return item.cust_id;
      });
      selectedAccounts.push(custIds.join(', ') + ' @' + key);
    });

    this.selectedAccountCount = selectedAccounts.length
    if (selectedAccounts) {
      let newUserData = {
        "allAccounts": this.userData.allAccounts,
        "username": this.userData.username,
        "userCompanyInfo": this.userData.userCompanyInfo,
        "selectedAccounts": selectedAccounts,
        "menu": this.userData.menu,
        "allowedUrls": this.userData.allowedUrls
      }
      this.selectedAccountsDisplay = selectedAccounts.length ? selectedAccounts.slice(0, 2) : []
      
      this.multipleAccountForSameWarehouse()
      this.selectedAccountsString = selectedAccounts.join(' | ');
      
      console.log(this.selectedAccountsDisplay)
      this.sessionStorageService.setData(
        customStorage.sessionKeys.user,
        JSON.stringify(newUserData)
      );

      localStorage.setItem('ArcUser', JSON.stringify(newUserData)) 
      this.modalService.dismissAll();
      this.commonService.notification(this.validationMessage.toasterClass.success, 'Account selection changed successfully')
      this.commonService.redirectToPage(navigationConstants.DASHBOARD);
    }
  }
}
export class MenuIconsExample { } 