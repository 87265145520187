import { Injectable } from '@angular/core';  
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ConfirmDialogService {
  private subject = new Subject<any>();

  constructor() { }

  confirmThis(message: string, siFn: () => void, noFn: () => void, bgClass: string = '') {
    this.setConfirmation(message, siFn, noFn, bgClass);
  }

  setConfirmation(message: string, siFn: () => void, noFn: () => void, bgClass) {
    let that = this;
    this.subject.next({
      type: "confirm",
      text: message,
      bgClass: bgClass,
      siFn:
        function () {
          that.subject.next(); // this will close the modal
          siFn();
        },
      noFn: function () {
        that.subject.next();
        noFn();
      }
    });
  }

  clearConfirmation() {
    this.subject.next(); // Clear the confirmation state
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
