import { Component, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Output } from '@angular/core';

@Component({
  selector: 'dropdown',
  template: `<ng-select (change)="handleInputChange($event,true)" [labelForId]="field.filterLabel" [search]="true" [clearable]="true" [multiple]="field.allowMultipleSelection" [defaultValue]="field.defaultSelectedValue" [items]="field.options" bindValue="dropDownValue" bindLabel="dropDownText" placeholder="Select" 
    [formControl]="form" [closeOnSelect]="!field.allowMultipleSelection">    
    
    <ng-template ng-header-tmp *ngIf="field.allowMultipleSelection && field.options != null && field.options.length>1">

    <div>
      <button class="btn btn-link"
              (click)="onSelectAll()">Select All</button>
      <button class="btn btn-link"
              (click)="onClearAll()">Clear All</button>
    </div>

  </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" *ngIf="field.allowMultipleSelection">
    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.dropDownText}}
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="field.allowMultipleSelection">
    <div *ngIf="items.length==1" class="ng-values-list">
      <div class="ng-value" *ngFor="let item of items | slice:0:2">
        <span class="ng-value-label">{{item.dropDownText}}</span>
        <span class="ng-value-icon right" (click)="clear(item)">×</span>
      </div>
    </div>
    <div *ngIf="items.length > 1" class="ng-summary-list">
      <div class="ng-value">
        <span class="ng-value-label">{{ items.length }} Selected</span>
      </div>
    </div>
  </ng-template>

    </ng-select>`
})
export class DropDownComponent {
  @Input() field: any = {};
  @Input() form: FormControl;
  @Output() onChange = new EventEmitter<any>();

  onChangeValue : boolean = false;
  selectedCounter: number = 0;
  usingArrayFrom:any;
  
  constructor() { }
  ngOnInit() {
    this.selectedCounter = this.form.value && this.form.value.length ? this.form.value.length : 0

    //Muitiple Dropdown Selection
    if (Array.isArray(this.form.value)) {
      const selectedObj = this.field && this.field.options ? this.field.options.filter(item => this.form.value.includes(item.dropDownValue)) : false;
      // console.log(selectedObj);
      if (selectedObj) {
        this.handleInputChange(selectedObj, this.onChangeValue);
      } else {
        this.handleInputChange(null, this.onChangeValue);
      }
    }
    //Single Dropdown Selection(Then form fields data is taken as string)
    else if (this.form.value){
      
      const selectedObj = this.field && this.field.options ? this.field.options.filter(item => this.form.value.includes(item.dropDownValue)) : false;
      // console.log(selectedObj);
      if (selectedObj) {
        this.handleInputChange(selectedObj, this.onChangeValue);
      } else {
        this.handleInputChange(null, this.onChangeValue);
      }
    }
     
  }

  //Will be used when dropdown value is changed and once at the time of initialisation
  handleInputChange($event, onChangeValue) {
    //handle the cross button
    if($event==undefined){
       $event=[];
     } 
    // //handle if user didn't select any value
    else if($event == null || $event.target !== undefined) {
      return;
    }
    //When value of change in dropdown i.e. event value is taken as object at the time of single dropdown selection.So converting this object into into array only
    let arr;
    if(typeof $event === 'object' && $event !== null && !Array.isArray($event)){
      arr=[$event];
      $event=arr;
      this.selectedCounter = $event.length;
    if (this.field.filterTypeBind) {
      this.onChange.emit({ value: $event, filterTypeBind: this.field.filterTypeBind, apiName: this.field.apiName, apiParameterName: this.field.apiParameterName, onchangevalue: onChangeValue });
    } else {
      this.onChange.emit(null);
    }

    }
    //When value of change in dropdown i.e. event value is taken as array.
    else{
    this.selectedCounter = $event.length;
    if (this.field.filterTypeBind) {
      this.onChange.emit({ value: $event, filterTypeBind: this.field.filterTypeBind, apiName: this.field.apiName, apiParameterName: this.field.apiParameterName, onchangevalue: onChangeValue });
    } else {
      this.onChange.emit(null);
    }}
  }

  public onSelectAll() {
    const selected = this.field.options.map(item => item.dropDownValue);
    const selectedObj = this.field.options.filter(item => item.dropDownValue);
    this.form.patchValue(selected);
    this.handleInputChange(selectedObj, this.onChangeValue)
  }

  public onClearAll() {
    this.form.patchValue([]);
    this.handleInputChange([], true);

  }

}