import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { APIConstant } from 'src/app/constants/apiConstants';
import { validationMessage } from 'src/app/constants/validationMessage';
import { ApiserviceService } from 'src/app/services/apiservice.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-business-intelligence',
  templateUrl: './business-intelligence.component.html',
  styleUrls: ['./business-intelligence.component.css']
})
export class BusinessIntelligenceComponent implements OnInit {

  tokenKey:any;
  isReportVisible:Boolean=false;
  isInboundOutboundReportVisible :  boolean =false
  isAMSReportVisible1 :  boolean =false
  isAMSReportVisible2 :  boolean =false
  validationMessage = validationMessage;
  isFirst: boolean = false
  currentUserEmail : any
  userData : any

  constructor(public spinner: NgxSpinnerService,
    private apiserviceService: ApiserviceService,
    private commonService: CommonService,) { 
      this.userData = JSON.parse(localStorage.getItem('ArcUser'))
    }

  ngOnInit(): void {
    this.getTableauData()
    this.getUserLoginDetail()
  }

  getTableauData(){
    this.spinner.show('dataLoader');
    
    this.apiserviceService.get(APIConstant.GenerateSignedToken, {UserEmail : this.currentUserEmail}).subscribe((response) => {
      if (response.result) {
        this.tokenKey=response.data
        this.spinner.hide('dataLoader');
        
      }
    }, error => {
      this.spinner.hide('dataLoader');
      // console.log("Error")
    })

  }

  getUserLoginDetail(){
    this.spinner.show('dataLoader');
    
    this.apiserviceService.get(APIConstant.GetUserLoginDetail, {username: this.userData.username}).subscribe((response) => {
      if (response.result) {
       this.currentUserEmail=response.data.portalUser[0].userEmailAddress
        console.log(this.currentUserEmail)
      }
    }, error => {
      this.spinner.hide('dataLoader');
      
    })
  }
  
  ReportVisible(){
    
    this.spinner.show('dataLoader');
    
    this.apiserviceService.get(APIConstant.GenerateSignedToken, {UserEmail : this.currentUserEmail}).subscribe((response) => {
      
        if(response.data=='401'){
          let errMsg = 'Not a Tableau user';
          this.commonService.notification(this.validationMessage.toasterClass.error,errMsg);
          this.isReportVisible=false
          this.isInboundOutboundReportVisible=false
          this.spinner.hide('dataLoader');
        }
        else{
          if(response.result){
            this.tokenKey=response.data
            this.spinner.hide('dataLoader');
            this.isReportVisible=true
            this.isInboundOutboundReportVisible=true
          } 
        }
    }, error => {
      this.spinner.hide('dataLoader');
    })
    
  }

  ReportVisibleAMS1(){
    this.spinner.show('dataLoader');
    
    this.apiserviceService.get(APIConstant.GenerateSignedToken, {UserEmail : this.currentUserEmail}).subscribe((response) => {
      
        if(response.data=='401'){
          let errMsg = 'Not a Tableau user';
          this.commonService.notification(this.validationMessage.toasterClass.error,errMsg);
          this.isReportVisible=false
          this.isAMSReportVisible1 = false
          this.spinner.hide('dataLoader');
        }
        else{
          if(response.result){
            this.tokenKey=response.data
            this.spinner.hide('dataLoader');
            this.isReportVisible=true
            this.isAMSReportVisible1 = true
          } 
        }
    }, error => {
      this.spinner.hide('dataLoader');
    })
  }

  ReportVisibleAMS2(){
    this.spinner.show('dataLoader');
    
    this.apiserviceService.get(APIConstant.GenerateSignedToken, {UserEmail : this.currentUserEmail}).subscribe((response) => {
      
        if(response.data=='401'){
          let errMsg = 'Not a Tableau user';
          this.commonService.notification(this.validationMessage.toasterClass.error,errMsg);
          this.isReportVisible=false
          this.isAMSReportVisible2 = false
          this.spinner.hide('dataLoader');
        }
        else{
          if(response.result){
            this.tokenKey=response.data
            this.spinner.hide('dataLoader');
            this.isReportVisible=true
            this.isAMSReportVisible2 = true
          } 
        }
    }, error => {
      this.spinner.hide('dataLoader');
    })
  }
}
