import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { ApiserviceService } from 'src/app/services/apiservice.service'
import { APIConstant } from 'src/app/constants/apiConstants'
import { SharedService } from 'src/app/services/shared.service'

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private connection: any;
  private proxy: any;
  userData:any=[]

  constructor(
    private apiserviceService: ApiserviceService,
    private sharedService: SharedService
  ) { 
    this.userData = JSON.parse(localStorage.getItem('ArcUser'));
  }


  //method for starting connection
  public initializeSignalRConnection(): void {
    let signalRServerEndPoint = environment.signalrPath;
    this.connection = $.hubConnection(signalRServerEndPoint);
    this.proxy = this.connection.createHubProxy('EnterpriseNotificationHub');

    this.proxy.on('messageReceived', (serverMessage) => this.onMessageReceived(serverMessage));
    this.connection.start().done((data: any) => {
      console.log('Connected to Notification Hub');
      this.broadcastMessage();
    }).catch((error: any) => {
      console.log('Notification Hub error -> ' + error);
    });
  }


  // private broadcastMessage(): void {
  //   this.proxy.invoke('SendTo', {},1)
  //     .catch((error: any) => {
  //       console.log('broadcastMessage error -> ' + error);
  //     });
  // }

  private broadcastMessage(): void {
    //console.log('BroadcastMessage')
    this.getNotificationCount();
  }

  getNotificationCount() {
    console.log('Calling notification count from signalr service')
    this.apiserviceService.get(APIConstant.GetPortalAlertCount, { UserName: this.userData.username }).subscribe(
      (response) => {
        let notiCount = parseInt(response.data.portalAlertCount)
        this.sharedService.allNotificationCount(notiCount)
      },
      error => {
        console.log("error", error)
      })
  }

  private onMessageReceived(serverMessage: string) {
    console.log('New message received from Server: ' + serverMessage);
  }
}
