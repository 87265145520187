export const APIConstant = { 
  Limit: 20,
  pwd_encryption_key: 'hnQVgxd4FE',  

  // Auth and user URL 
  LOGIN: 'Login/GetUserLogin',
  UpdAcceptTermsTimestamp: 'UserAccount/UpdAcceptTermsTimestamp', 
  getAndSetMenuData: 'UserAccount/GetMenuDisplay',
  GetReportDisplay: 'UserAccount/GetReportDisplay', 
  CHANGE_PASSWORD: 'UserAccount/UpdateUserPwd',
  ForgetPassword: 'UserAccount/ForgetPassword',
  ResetUserPassword: 'UserAccount/ResetUserPassword', 
  UpdateUserLockedOutStatus: 'UserAccount/UpdateUserLockedOutStatus', 

  //Master apis
  GetCityStateZip: 'Master/GetCityStateZip',
  GetCountryZip:'Master/GetCountryList',
  GetMobileProviders: 'Master/GetMobileProviders',
  GetSites: 'Master/GetSites',
  GetAccounts: 'Master/GetAccounts',
  GetGlobalSettings: 'Master/GetGlobalSettings',
  UpdateGlobalSettings: 'Master/UpdateGlobalSettings',
  GetAllUserEmails: 'Master/GetAllUserEmails',
  GetUserNameList: 'Master/GetUserNameList',
  GetSitesbyUser: 'Master/GetSitesbyUser',
  GetUserAllAccounts: 'Master/GetUserAllAccounts',
  InsertUserAccounts: 'Master/InsertUserAccounts',
  GetConfigurationCategoryMaster: 'Master/GetConfigurationCategoryMaster',
  GetConfigurationCategoryDetailByID: 'Master/GetConfigurationCategoryDetailByID',
  InsertUpdConfigurationCategoryDetail: 'Master/InsertUpdConfigurationCategoryDetail',
  GetGlobalConfigValue: 'Master/GetGlobalConfigValue',
  GetDashboardWrapper: 'Master/GetDashboardWrapper',
  OrderUploadFiles:'Master/OrderUploadFiles',
  DeleteOrderUploadFiles:'Master/DeleteOrderUploadFiles',
  GetUserName:'Master/GetUserName',
  GetCustomerPONumber:'Master/GetCustomerPONumber',
  GetSalesOrderNumber:'Master/GetSalesOrderNumber',
  GetMasterBol:'Master/GetMasterBOL',
  GetSFONumber:'Master/GetSFONumber',
  GetReportDownloadMasterData:'Master/GetReportDownloadMasterData',
  GetReportDownloadMaterDataExcel:'Master/GetReportDownloadMaterDataExcel',
  GetERPCLientIdFromUserName:'UserAccount/GetERPCLientIdFromUserName',



  // outbound
  GetOrderChangesLog: 'Master/GetOrderChangesLog',
  SendExcelForOrderLog: 'Master/SendExcelForOrderLog',
  GetOrderChangesLogExcel: 'Master/GetOrderChangesLogExcel',
  GetOrderChangesLogPDF: 'Master/GetOrderChangesLogPDF',

  //inbound
  GetReceiptChangesLog:'Master/GetReceiptChangesLog',
  SendExcelForReceiptLog: 'Master/SendExcelForReceiptLog',
  GetReceiptChangesLogExcel: 'Master/GetReceiptChangesLogExcel',
  GetReceiptChangesLogPDF: 'Master/GetReceiptChangesLogPDF',


  //mass upload orders
  GetValidation:'Master/ValidateBulkOrder',

  //order upload Robinson
  SaveChrobinsonExcel:'Master/SaveChrobinsonExcel',

  //online order outbound
  GET_PENDING_ORDERS:'OnlineOrder/GetPendingOrders',
  GET_ORDER_SCREEN_ATTRIBUTES:'OnlineOrder/OrderScreenAttributes',
  OrderInventoryAvailable: 'OnlineOrder/OrderInventoryAvailable',
  InsertOnlineOrder:'OnlineOrder/InsertOnlineOrder',
  GetOnlineOrderInfo:'OnlineOrder/GetOnlineOrderInfo',
  DeleteOnlineOrder:'OnlineOrder/DeleteOnlineOrder',
  UpdateOnlineOrderHeaderOnSubmit: 'OnlineOrder/UpdateOnlineOrderHeaderOnSubmit',
  InsertOnlineOrderConsignee: 'OnlineOrder/InsertOnlineOrderConsignee',
  GetOutboundOrderStatusList:'OnlineOrder/GetOutboundOrderStatusList',
  GetOrderTypes: 'OnlineOrder/GetOrderTypes',
  deleteOnlineOrder: 'OnlineOrder/deleteOnlineOrder',
  GetCutOffTime: 'OnlineOrder/GetCutOffTime',
  GetItemAvailQtyHTMLTable: 'OnlineOrder/GetItemAvailQtyHTMLTable',
  CheckOutboundCustomerPONumber: 'OnlineOrder/CheckOutboundCustomerPONumber',
  CheckOutboundWMSOrderNumber: 'OnlineOrder/CheckOutboundWMSOrderNumber',
  getOutboundOrderDispatchedStatusAlertMain: 'OnlineOrder/getOutboundOrderDispatchedStatusAlertMain',
  getOutboundOrderDispatchedStatusAlertByID: 'OnlineOrder/getOutboundOrderDispatchedStatusAlertByID',
  InsUpdOutboundOrderDispatchedStatusAlert: 'OnlineOrder/InsUpdOutboundOrderDispatchedStatusAlert',

  //online order Inbound
  GetReceiptInventoryAvailable: 'OnlineInboundOrder/GetReceiptInventoryAvailable',
  GetPendingReceipts:'OnlineInboundOrder/GetPendingReceipts',
  GetReceiptStatusList:'OnlineInboundOrder/GetReceiptStatusList',
  GetReceiptTypes:'OnlineInboundOrder/GetReceiptTypes',
  ReceiptScreenAttributes:'OnlineInboundOrder/ReceiptScreenAttributes',
  InsertOnlineReceipt:'OnlineInboundOrder/InsertOnlineReceipt',
  GetOnlineReceiptInfo:'OnlineInboundOrder/GetOnlineReceiptInfo',
  DeleteOnlineReceipt:'OnlineInboundOrder/DeleteOnlineReceipt',
  InsertOnlineOrderShipper:'OnlineInboundOrder/InsertOnlineOrderShipper',
  UpdateOnlineReceiptHeaderOnSubmit:'OnlineInboundOrder/UpdateOnlineReceiptHeaderOnSubmit',
  CheckInboundCustomerReceiptNumber:'OnlineInboundOrder/CheckInboundCustomerReceiptNumber',
  GetInboundWarehouseRcptInfo:'OnlineInboundOrder/GetInboundWarehouseRcptInfo',
  CheckInboundWMSReceiptNumber:'OnlineInboundOrder/CheckInboundWMSReceiptNumber',
  GetOrderUploadFilesByOrderNo:'Master/GetOrderUploadFilesByOrderNo',
  

  //Reports
  GetFilterReportData : 'ReportFilter/GetFilterReportData',
  GetRptOrderSummaryDetail : 'ReportFilter/GetRptOrderSummaryDetail',
  GetReportDetailbyReportID : 'ReportFilter/GetReportDetailbyReportID',
  GetSitesByCompany : 'ReportFilter/GetSitesByCompany', 
  GetDrillDownReport : 'ReportFilter/GetDrillDownReport',  
  GetPackingListInformation : 'ReportFilter/GetPackingListInformation',  
  GetReportFilterAccounts : 'ReportFilter/GetAccounts',
  GetWarehouseOrderNumber : 'ReportFilter/GetWarehouseOrderNumbers',
  GetMasterReceiptNumber : 'ReportFilter/GetMasterReceiptNumber',
  GetSupplierInvoiceNumber : 'ReportFilter/GetSupplierInvoiceNumber',
  GetOrderChangesWarehouseOrderNumber : 'ReportFilter/GetOrderChangeWarehouseOrderNumbers',
  GetOrderChangesSupplierInvoiceNumber : 'ReportFilter/GetOrderChangeSupplierInvoiceNumber',

  //Business Intelligence
  GenerateSignedToken: 'ReportFilter/GenerateSignedToken',

  

  //Delivery Ticket
  GetDTBOL : 'DeliveryTicket/GetDTBOL', 
  GetMasterBOL : 'DeliveryTicket/GetMasterBOL', 

  //Adminstration 
  GetUserLoginMain: 'UserAccount/GetUserLoginMain',
  GetUserTypeList: 'UserAccount/GetUserTypeList',
  GetUserLoginDetail: 'UserAccount/GetUserLoginDetail',
  GetCompanySitesList:'UserAccount/GetCompanySites',
  getCompanySiteAccountsList:'UserAccount/GetCompanySiteAccounts',
  InsertUpdUserLoginDetail:'UserAccount/InsertUpdUserLoginDetail',
  UpdateUserForgetPassword:'UserAccount/UpdateUserForgetPassword',
  DeleteUser:'UserAccount/DeleteUser',
  GetTermAndConditions:'UserAccount/GetTermAndConditions', 
  GetUserNameForCompanyCode:'UserAccount/GetUserNameForCompanyCode', 
  GetUserReportSetup:'UserAccount/GetUserReportSetup', 
  UpdateUserReportSetup:'UserAccount/UpdateUserReportSetup', 
  insertUserLogOutHistory:'UserAccount/insertUserLogOutHistory', 
  GetUserNameForCompany:'UserAccount/GetUserNameForCompany', 
  GetUserReport:'UserAccount/GetUserReport', 
  GetUserActivity:'UserAccount/GetUserActivity', 
  GetCompanyList:'UserAccount/GetCompanyList', 
  GetUserSiteInfo:'UserAccount/GetUserSiteInfo', 
  ResetPwdByAdminorCS:'UserAccount/ResetPwdByAdminorCS', 
  GetPalletCapacity:'UserAccount/GetPalletCapacity',
  GetPalletCapacityById:'UserAccount/GetPalletCapacityById',
  DeletePalletCapacityById:'UserAccount/DeletePalletCapicity',
  InsertPalletCapacity:'UserAccount/InsertUpdPalletCapacity',
  CheckExistingEmail:'UserAccount/CheckExistingEmail',

  GetFixedPalletCommitments:'UserAccount/GetFixedPalletCommitments',
  GetFixedPalletCommitmentsById:'UserAccount/GetFixedPalletCommitmentsById',
  DeleteFixedPalletCommitments:'UserAccount/DeleteFixedPalletCommitments',
  InsertUpdPalletCommitments:'UserAccount/InsertUpdPalletCommitments',

  //Alert & Notifications
  GetScheduleReportInfo : 'ScheduleReport/GetScheduleReportInfo',
  GetScheduleReportList : 'ScheduleReport/GetScheduleReportList',
  GetCompanyCodeList : 'ScheduleReport/GetCompanyCodeList',
  GetCompanyAccounts : 'ScheduleReport/GetCompanyAccounts',
  GetSupportKeyValue : 'ScheduleReport/GetSupportKeyValue',
  InsUpdScheduleReport : 'ScheduleReport/InsUpdScheduleReport',
  DelScheduleReport : 'ScheduleReport/DelScheduleReport',
  GetReportRollupType : 'ScheduleReport/GetReportRollupType',
  GetScheduledInfoWithID : 'ScheduleReport/GetScheduledInfoByID',
  GetCompanyAllAccounts : 'ScheduleReport/GetCompanyAllAccounts',
 
  //Customer Requests
  GetCustomerRequestInfo : 'CustomerRequest/GetCustomerRequestInfo',
  getCustomerRequestType : 'CustomerRequest/getCustomerRequestType',
  GetCustomerRequestPriority : 'CustomerRequest/GetCustomerRequestPriority',
  getCustomerRequestStatus : 'CustomerRequest/getCustomerRequestStatus',
  getCustomerRequestAssignedGroup : 'CustomerRequest/getCustomerRequestAssignedGroup',
  insertUpdCustomerRequest : 'CustomerRequest/insertUpdCustomerRequest',
  getCustomerRequestByID : 'CustomerRequest/getCustomerRequestByID',


  //manage company
  GetSelectedAccounts : 'ManageCompanyAccounts/GetSelectedAccounts',
  GetAvailableAccounts : 'ManageCompanyAccounts/GetAvailableAccounts',
  InsertCompanyAccounts : 'ManageCompanyAccounts/InsertCompanyAccounts', 
  GetCompanyMain : 'ManageCompany/GetCompanyMain', 
  InsertUpdCompany : 'ManageCompany/InsertUpdCompany', 
  GetCompanyMasterInfo : 'ManageCompany/GetCompanyMasterInfo', 
  DeleteCompany : 'ManageCompany/DeleteCompany', 
  GetWarehouseSetupWrapper : 'ManageCompany/GetWarehouseSetupWrapper', 
  InsertUpdCompanySites : 'ManageCompany/InsertUpdCompanySites', 
  DeleteCompanySites : 'ManageCompany/DeleteCompanySites',  
  VerifyCompCode : 'ManageCompany/VerifyCompCode',  
  GetCompanyStatus : 'ManageCompany/GetCompanyStatus',  

  //EDI
  GetEDITagsConfiguration : 'EDI/GetEDITags',
  GetMappedTags : 'EDI/GetEDITagsDropdown',
  InsertEdiTagConfig : 'EDI/InsertUpdEDITagConfig',
  GetEDITypes : 'EDI/GetEDITypes',
  InsertUpdEDITagMapping : 'EDI/InsertUpdEDITagMapping',

  //Customer Message
  GetBroadcastMessageMain : 'CustomerMessage/GetBroadcastMessageMain',  
  DeleteBroadcastMessage : 'CustomerMessage/DeleteBroadcastMessage',  
  InsertUpdBroadcastMessage : 'CustomerMessage/InsertUpdBroadcastMessage',  
  UpdateBroadcastMessageStatus : 'CustomerMessage/UpdateBroadcastMessageStatus',  
  GetBroadcastMessagebyID : 'CustomerMessage/GetBroadcastMessagebyID',  
  GetLiveBroadcastMessage : 'CustomerMessage/GetLiveBroadcastMessage',  

  //Item Master
  GetItemFootprintMain : 'ItemMaster/GetItemFootprintMain',  
  GetItemBaseUOM : 'ItemMaster/GetItemBaseUOM',  
  GetItemFootprintDetailsByItemID : 'ItemMaster/GetItemFootprintDetailsByItemID', 
  GetLenWidthHghtUOM : 'ItemMaster/GetLenWidthHghtUOM', 
  GetTempUOM : 'ItemMaster/GetTempUOM', 
  GetVolumeUOM : 'ItemMaster/GetVolumeUOM', 
  GetWeightUOM : 'ItemMaster/GetWeightUOM', 
  InsertUpdItemFootprintMaster : 'ItemMaster/InsertUpdItemFootprintMaster', 
  UpdateItemFootprintMasterOnEmailSent : 'ItemMaster/UpdateItemFootprintMasterOnEmailSent', 
  UpdateItemFootprintMasterOnSubmit : 'ItemMaster/UpdateItemFootprintMasterOnSubmit', 
  GetItemFootprintMasterWrapper : 'ItemMaster/GetItemFootprintMasterWrapper', 
  DeleteItemFootprintMaster : 'ItemMaster/DeleteItemFootprintMaster',
  GetOperationsEmailMain : 'Master/GetOperationsEmailMain',
  GetOperationsEmailByID : 'Master/GetOperationsEmailByID',
  InsUpdOperationsEmail : 'Master/InsUpdOperationsEmail',



  //Manage CSR 
  GetCSR : 'CSR/GetCSR', 
  GetCSRByID : 'CSR/GetCSRByID', 
  GetCSRSites : 'CSR/GetCSRSites', 
  DeleteCSR : 'CSR/DeleteCSR', 
  InsertUpdateCSR : 'CSR/InsertUpdateCSR', 
  GetSiteAccount: 'UserAccount/GetAllSiteAccount',  

  // Holiday
  GetHolidatScheduleDetail: 'Holiday/GetHolidayScheduleDetails',
  InsertUpdateSchedule: 'Holiday/InsUpdHolidaySchedule',
  DeleteHolidaySchedule: 'Holiday/DeleteHolidaySchedule',
  GetMasterCutOffTimeforHoliday: 'Holiday/getMasterCutOffTimeforHoliday',

  GetHolidaysCalender: 'Holiday/getHolidaysCalender',
  InsertUpdHolidaysCalender: 'Holiday/InsertUpdHolidaysCalender',

  // Order CutOff Master
  GetOrderCutOffMain: 'Master/GetOrderCutOffMain',
  UpdateOrderCutoffTime: 'Master/UpdateOrderCutoffTime',
  GetWarehouseTimeZone: 'Master/getWarehouseTimeZone',
  
  
  // alertnotification 
  GetCarrierList : 'ReportFilter/getCarrierList',
  GetItemAlertList : 'ItemMaster/getItemAlertList',
  GetShipperAlertList : 'OnlineInboundOrder/getShipperAlertList',
  GetNotificationAlertTypes: 'Master/GetNotificationAlertTypes',
  InsertUpdateAlertMaster : 'Master/insertUpdateAlertMaster', 
  GetAlertMasterMain : 'Master/getAlertMasterMain', 
  DeleteAlertMaster : 'Master/deleteAlertMaster',
  GetAlertMasterById: 'Master/GetAlertMasterById',
  UpdateAlertMasterStatus : 'Master/updateAlertMasterStatus',
  GetConsigneeList : 'Master/getConsigneeList',
  GetConsigneesAlertList : 'Master/GetConsigneesAlertList',
  GetInvoiceChargeTypes: 'Master/GetInvoiceChargeTypes',
  GetShipmentTypes: '',
  GetTimeZones: 'Master/GetTimeZones',
  GetAlertMessage: 'Master/GetInputAlertMessage',

  //notification
  GetPortalAlertCount : 'Master/getPortalAlertCount',
  GetPortalAlertNotification : 'Master/getPortalAlertNotification',
  UpdatePortalView : 'Master/updatePortalView',

  // Customer BOL Temperatures
  GetCustomerBOLTemperaturesMain: 'DeliveryTicket/GetCustomerBOLTemperaturesMain',
  GetBOLCustomerCodeBySite: 'DeliveryTicket/GetBOLCustomerCodeBySite',
  GetCustomerBOLTemperaturesByID: 'DeliveryTicket/GetCustomerBOLTemperaturesByID',
  InsUpdCustomerBOLTemperatures: 'DeliveryTicket/InsUpdCustomerBOLTemperatures',

  // Learning Centre 
  LearningCenter: 'Learning/GetLearningCenterContent'
  
}; 