<!-- Middle Part -->
<div class="middle-part">
    <div class="clear"></div>
    <div class="container-fluid p-0">
        <!-- -- Reports start -- -->
        <div class="col-md-12 mb-4">
            <div class="row">
                <!-- first block start -->
                <div class="col-md-12 mt-3">
                    
                    <div class="white-content-area pb-3">
                        <div class="col-md-12">
                            <div class="col-md-8">
                                <h2 class="text-left fw-bold">Appointment</h2>
                            </div>
                            <ngx-spinner name="dataLoader" [fullScreen]="false" type="timer" size="medium"></ngx-spinner>
                            <div class="col-md-12">
                                <div style="visibility: hidden;">
                                    Proceed to the Appointment Management System
                                    <!-- <p>Proceed to BlueYonder Appointment Scheduling application</p> -->
                                </div>
                                
                                 <!-- <p>Proceed to BlueYonder Appointment Scheduling application</p> -->
                                <p>Proceed to the Appointment Management System</p>
                                <a (click)="onNavigate()" class="mt-4" style="color:#00a1e1"
                                    href="javascript:void();"><strong>Click Here</strong> </a>
                                <div style="visibility: hidden;">
                                    Proceed to the Appointment Management System
                                    <!-- <p>Proceed to BlueYonder Appointment Scheduling application</p> -->
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>