import { Component, OnInit, Pipe, PipeTransform, ViewEncapsulation } from '@angular/core';
import { ApiserviceService } from 'src/app/services/apiservice.service';
import { CommonService } from 'src/app/services/common.service';
import { APIConstant } from "src/app/constants/apiConstants";
import { validationMessage } from 'src/app/constants/validationMessage'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Pipe({ name: 'noSanitizeHtml' })
export class NoSanitizeHtmlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }

  transform(html: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}

@Component({
  selector: 'app-learning-center',
  templateUrl: './learning-center.component.html',
  styleUrls: ['./learning-center.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class LearningCenterComponent implements OnInit {
  userData: any;
  data : any = '';

  constructor(
    private commonService: CommonService,
    private apiserviceService: ApiserviceService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.spinner.show()
    this.userData = JSON.parse(localStorage.getItem('ArcUser'));
    this.apiserviceService.get(APIConstant.LearningCenter, { userName: this.userData.username }).subscribe(
      response => {
        this.spinner.hide()
        this.data = response.data.description
      },
      error => {
        this.spinner.hide()
        this.commonService.notification(validationMessage.toasterClass.error, 'Something went wrong!');
      }
    );

    $(document).ready(function () { 
        $(document).on('click', '#learning-center .to-top', function(){
      	$('html, body').animate({ scrollTop: 0 }, 300);
      });
    });
  }
}
