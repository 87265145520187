import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Output } from '@angular/core';

@Component({
  selector: 'field-builder',
  template: `
  <div class="form-group" [formGroup]="form">
    <label [attr.for]="field.filterLabel"><h5><span *ngIf="field.filterType !== 'checkbox'"> {{field.filterLabel}}</span>
      <strong class="text-danger" *ngIf="field.isRequired && form.get(field.filterCode).invalid">*</strong></h5>
    </label>
    <div [ngSwitch]="field.filterType" [ngClass]="{'checkboxBlk': field.filterType == 'checkbox'}">
      <textbox *ngSwitchCase="'textbox'" [field]="field" [form]="form.get(field.filterCode)"></textbox>
      <datepicker *ngSwitchCase="'singledate'" [field]="field" [form]="form.get(field.filterCode)"></datepicker>
      <dropdown  *ngSwitchCase="'dropdown'" [field]="field" [form]="form.get(field.filterCode)" (onChange)="callSomeFunction($event)"></dropdown>
      <checkbox *ngSwitchCase="'checkbox'" [field]="field" [form]="form.get(field.filterCode)"></checkbox>
      <radio *ngSwitchCase="'radio'" [field]="field" [form]="form.get(field.filterCode)"></radio>
      <file *ngSwitchCase="'file'" [field]="field" [form]="form.get(field.filterCode)"></file>
      <!-- <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty">{{field.label}} is required</div> -->
    </div>
  </div>
  `
})
export class FieldBuilderComponent implements OnInit {
  @Input() field:any;
  @Input() form:any;
  
  get isValid() { return this.form.controls[this.field.filterCode].valid; }
  get isDirty() { return this.form.controls[this.field.filterCode].dirty; }

  @Output() onChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { } 

  callSomeFunction($event){  
    this.onChange.emit($event);  
    //console.log("this.form.get(this.field.filterCode)",this.form.get(this.field.filterCode))
  }
  

}
