import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { SessionStorageService } from "src/app/services/session-storage.service";
import { customStorage } from "src/app/constants/storageKeys";
import { validationMessage } from "src/app/constants/validationMessage";
import { navigationConstants } from "src/app/constants/navigationConstant";

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  hexBase = 10;
  validationMessage=validationMessage;
  _keyStr= "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

  constructor(
    private toastr: ToastrService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
  ) { }

  set_cookie(name, value) {
    var cookie = [name, '=', JSON.stringify(value), '; domain=.', window.location.host.toString(), '; path=/;'].join('');
    document.cookie = cookie;
  }
  read_cookie(name) {
    var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
    result && (result = JSON.parse(result[1]));
    return result;
   }
   

  notification(noticationType: string, message: string, title?: string) {
    this.toastr.clear();
    let titleMessage = title ? title : noticationType.charAt(0).toUpperCase() + noticationType.slice(1);
    this.toastr[noticationType](message, titleMessage, {
      positionClass: "toast-bottom-center",
      timeOut: 5000,
      maxOpened: 1,
      preventDuplicates: true,
      autoDismiss: true
    });
  }

  notificationSessionExpire(noticationType: string, message: string, title?: string) {
    this.toastr.clear();
    let titleMessage = title ? title : noticationType.charAt(0).toUpperCase() + noticationType.slice(1);
    this.toastr[noticationType](message, titleMessage, {
      positionClass: "toast-bottom-center",
      timeOut: 600000*900,
      maxOpened: 1,
      preventDuplicates: true,
      autoDismiss: true
    });
  }

  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }
  getToken(){
    let token=null;
    let toeknGet=this.sessionStorageService.getData(customStorage.sessionKeys.token)
    if(toeknGet!=null && toeknGet!=undefined && toeknGet!=''){
      token=toeknGet;
    }
    return token;
  }

  redirectToPage(url:string) {
    this.router.navigate([url]);
  }

  clearSession(){
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('user')

    localStorage.removeItem('ArcLocalToken')
    localStorage.removeItem('ArcUser')
    
    sessionStorage.clear();
    localStorage.clear(); 
  }
  logout(){
    this.clearSession(); 
    this.redirectToPage(navigationConstants.LOGIN);
  } 

  encrypt(value: number) {
    // console.log(value);
    
    return this.Base64EncodeUrl(btoa(value.toString(this.hexBase)));
  }

  decrypt(value: string) {
    // console.log(value);
    
    try {
      return parseInt(this.Base64DecodeUrl(atob(value)), this.hexBase);
    } catch {
      this.router.navigate(["/404"]);
    }
    return 0;
  }

  encryptStr(value: string) {
    return btoa(value);
  }

  decryptStr(value: string) {
    try {
      return atob(value)
    } catch {
      this.router.navigate(["/404"]);
    }
    return 0;
  }

  Base64EncodeUrl(str) {
    return str
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/\=+$/, "");
  }
  Base64DecodeUrl(str) {
    if (str.length % 4 != 0) str += "===".slice(0, 4 - (str.length % 4));
    return str.replace(/-/g, "+").replace(/_/g, "/");
  }
  getPermission(module,subodule){
    let permissionData=JSON.parse(this.sessionStorageService.getData(customStorage.sessionKeys.permissions));
    if(module && !subodule){
      return permissionData[module];
    } else {
      return permissionData[module].subModule[subodule];
    }
  }
  

   
  encode(input) {
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    input = this._utf8_encode(input);

    while (i < input.length) {

        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        } 
        output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);

    } 
    return output;
}


decode(input) {
    var output = "";
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;

    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

    while (i < input.length) {

        enc1 = this._keyStr.indexOf(input.charAt(i++));
        enc2 = this._keyStr.indexOf(input.charAt(i++));
        enc3 = this._keyStr.indexOf(input.charAt(i++));
        enc4 = this._keyStr.indexOf(input.charAt(i++));

        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;

        output = output + String.fromCharCode(chr1);

        if (enc3 != 64) {
            output = output + String.fromCharCode(chr2);
        }
        if (enc4 != 64) {
            output = output + String.fromCharCode(chr3);
        }

    }

    output = this._utf8_decode(output);

    return output;

}

_utf8_encode(string) {
    string = string.replace(/\r\n/g, "\n");
    var utftext = "";

    for (var n = 0; n < string.length; n++) {

        var c = string.charCodeAt(n);

        if (c < 128) {
            utftext += String.fromCharCode(c);
        }
        else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
        }
        else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
        }

    }

    return utftext;
}

_utf8_decode(utftext) {
  var string = "";
  var i = 0;
  var c = 0;
  var c1 = 0;
  var c2 = 0;
  var c3;

  while (i < utftext.length) {

      c = utftext.charCodeAt(i);

      if (c < 128) {
          string += String.fromCharCode(c);
          i++;
      }
      else if ((c > 191) && (c < 224)) {
          c2 = utftext.charCodeAt(i + 1);
          string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
          i += 2;
      }
      else {
          c2 = utftext.charCodeAt(i + 1);
          c3 = utftext.charCodeAt(i + 2);
          string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
          i += 3;
      }
  }
  return string;
}  

}
