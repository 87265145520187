import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared.module';
import { AngularMaterialModule } from './modules/angular-material.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { MatIconModule } from '@angular/material/icon';

// for intercepter
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DEFAULT_TIMEOUT, RequestInterceptor } from './intercepter/request.interceptor';
import {  LoadingInterceptor } from './intercepter/loading.interceptor';


// components
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { AppointmentComponent } from './pages/appointment/appointment.component'
import { P404Component } from './pages/p404/p404.component';
import { P500Component } from './pages/p500/p500.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component'; 
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
// for dateTimepicker
//import { OwlDateTimeModule, OwlMomentDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { OwlDateTimeModule, OwlMomentDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from '@danielmoncada/angular-datetime-picker';

import { NgIdleModule } from "@ng-idle/core";
//
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
//import { DataTablesModule } from "angular-datatables";
import { MatTabsModule } from '@angular/material/tabs';
import { AuthGuard } from './guard/auth.guard';
import { LearningCenterComponent, NoSanitizeHtmlPipe } from './pages/learning-center/learning-center.component';
import { environment } from 'src/environments/environment';
import { BusinessIntelligenceComponent } from './pages/business-intelligence2/business-intelligence/business-intelligence.component';
import { MatExpansionModule } from '@angular/material/expansion';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'MM YYYY',
  },
};

export const MY_MOMENT_FORMATS = {
  parseInput: 'MM/DD/YYYY HH:mm:ss',
  fullPickerInput: 'MM/DD/YYYY HH:mm:ss',
  datePickerInput: 'MM/DD/YYYY',
  timePickerInput: 'HH:mm:ss',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AppointmentComponent,
    P404Component,
    P500Component,
    DashboardComponent,
    HeaderComponent,
    FooterComponent, 
    ConfirmDialogComponent,
    ResetPasswordComponent,
    LearningCenterComponent,
    NoSanitizeHtmlPipe,
    BusinessIntelligenceComponent,
  ],
  imports: [
    MatTabsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    // MaterialModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    AngularMaterialModule,
    NgxChartsModule,
    MatExpansionModule,
    //DataTablesModule,
    RecaptchaModule.forRoot({
      siteKey: '6LfR2xMUAAAAABBy2TBrBKur0_L23TizUGR-M20s',
    }),
    NgIdleModule.forRoot(),
    OwlDateTimeModule, OwlNativeDateTimeModule, OwlMomentDateTimeModule
  ],
  exports: [
    ConfirmDialogComponent,
  ],
  providers: [
    ConfirmDialogService,
    // SnackBarService,
    // SharedService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    //{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    // BnNgIdleService
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: DEFAULT_TIMEOUT, useValue: environment.httpRequestTimeout },
    AuthGuard
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
