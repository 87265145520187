import { Directive, HostListener, Input } from '@angular/core';
import { regExConstant } from 'src/app/constants/regExConstant';

@Directive({
  selector: '[floatNumberOnly]'
})
export class FloatNumberOnlyDirective {
  //float numbers allowed in text box
  
  @Input() isAlphaNumeric: boolean;

  @HostListener('keypress', ['$event']) onKeyPress(event) {
  return new RegExp(regExConstant.floatNumberOnly).test(event.key);
  }
  constructor() { }

}
