export const customStorage = {
  sessionKeys: {
    token: 'token',
    openSkyToken: 'openSkyToken',
    state: 'state',
    log_request_method: "log_request_method",
    max_login_attempt: "max_login_attempt",
    pageLength: "pageLength",
    token_validity: "token_validity",
    user: "user",
    permissions: "permissions",
    reportFromDate: "reportFromDate",
    reportToDate: "reportToDate",
    reportType: "reportType",
    reportData: "reportData",
    smsCat: "smsCat",
    statusTypeCat: "statusTypeCat",
    dataTypeCat: "dataTypeCat",
    access_token: "access_token",
    patientsummary: "patientsummary",
    abdm_individual_info: "abdm_individual_info",
    abdm_access_token:"abdm_access_token"
  },
  localKeys: {
    token: 'token'
  }
};
