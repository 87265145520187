import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-p500',
  templateUrl: './p500.component.html',
  styleUrls: ['./p500.component.css']
})
export class P500Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
