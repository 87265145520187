import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { CommonService } from "src/app/services/common.service";
import { ApiserviceService } from "src/app/services/apiservice.service";
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { APIConstant } from "src/app/constants/apiConstants";
import { validationMessage } from "src/app/constants/validationMessage";
import * as _ from 'lodash'
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  breadcrumbObj = {
    title: 'Dashboard',
    classBreadcrumb: 'fa-dashboard',
    urls: [
      { urlName: 'Home', linkURL: '/dashboard' }
    ]
  }
  validationMessage = validationMessage;
  closeResult: string;
  messageList: any = [];
  urlLinks: any = [];
  userData: any = [];
  userSiteInfo: string = '';
  todayDate: Date = new Date()
  customerRequestforDashboard: any = []
  holidayScheduleforDashboard: any = []
  timezoneDate: any = ''
  verbiageMessage: string = ''
  

  constructor(
    private commonService: CommonService,
    private apiserviceService: ApiserviceService,
    private sessionStorageService: SessionStorageService,
    private spinner: NgxSpinnerService,
    public router: Router,
    public titleService:Title,
    
  ) {
    //let preLoginUrl = this.sessionStorageService.getData('preLoginUrl') 
    let preLoginUrl = localStorage.getItem('preLoginUrl')

    if (preLoginUrl) {
      this.sessionStorageService.deleteData('preLoginUrl')
      this.router.navigate([preLoginUrl])
      localStorage.removeItem('preLoginUrl')
    }
    this.userData = JSON.parse(localStorage.getItem('ArcUser'));
  }

  ngOnInit(): void {
    this.GetDashboardWrapper()
    this.titleService.setTitle(validationMessage.common.heading+"Dashboard")
    
  }

  GetDashboardWrapper() {
    let payload = {
      UserName: this.userData.username
    }
    this.spinner.show('dataLoader')
    this.apiserviceService.get(APIConstant.GetDashboardWrapper, payload).subscribe((response) => {
      if (response.result) {
        this.urlLinks = response.data['dashboardLinkDetail']
        this.messageList = response.data['liveBroadCastMessage'].dashboardMessage
        this.userSiteInfo = response.data['userSiteInfo']
        this.customerRequestforDashboard = response.data['customerRequestforDashboard']
        this.timezoneDate = response.data['dashboardDateTimeDetails'].timeZone
        this.verbiageMessage = response.data['messageDetails'].verbiageMessage
        let tempList = _.groupBy(response.data['holidayScheduleforDashboard'], item => item.siteID);
        let tempArr = []
        Object.keys(tempList).forEach(key => {
          tempArr.push({
            warehouse: key,
            holidays: tempList[key]
          })
        })
        this.holidayScheduleforDashboard = tempArr
      }

      this.spinner.hide('dataLoader');
    }, error => {
      this.spinner.hide('dataLoader')
      let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong!'
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg);
    })
  }

}
