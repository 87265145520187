import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SessionStorageService {
  constructor() {}

  setData(keys: string, data: any) {
    sessionStorage.setItem(keys, data);
  }

  getData(keys: string) {
    return sessionStorage.getItem(keys);
  }
  
  getParsedData(keys: string) {
    return JSON.parse(sessionStorage.getItem(keys));
  }

  deleteData(keys: string) {
    sessionStorage.removeItem(keys);
  }

  deleteAll() {
    sessionStorage.clear();
  }
}
