import { Component, OnInit } from '@angular/core'; 
import { FormBuilder,FormGroup,Validators} from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner"; 
import { PasswordValidator } from 'src/app/customvalidator/password.validator';
import { passwordNoWhitespaceValidator } from 'src/app/customvalidator/passwordNoWhitespace.validator';
import { CommonService } from "src/app/services/common.service";
import { ApiserviceService } from "src/app/services/apiservice.service"; 
import { APIConstant } from "src/app/constants/apiConstants"; 
import { validationMessage } from "src/app/constants/validationMessage";
import { navigationConstants } from "src/app/constants/navigationConstant";
import { regExConstant } from '../../constants/regExConstant'
const shajs = require('sha.js');


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  validationMessage=validationMessage; 
  userData:any;
  updatePassword: FormGroup;
  isUpdatePasswordSubmitted = false;
  btnSpinner:boolean=false;
  bodyBgImg: string;

  constructor(
    private commonService: CommonService,
    private apiserviceService: ApiserviceService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
  ) {    
    let bgm = ['assets/images/bg01.jpg']
    this.bodyBgImg = bgm[Math.floor(Math.random() * bgm.length)]; 
  }

  ngOnInit(): void {
    this.updatePassword = this.formBuilder.group({
     password: ["",[Validators.required,passwordNoWhitespaceValidator.cannotContainSpace, Validators.minLength(12),Validators.maxLength(30), Validators.pattern(regExConstant.passwordValidators)]],
      c_password: ["", Validators.required]
    }, { validator: PasswordValidator("password", "c_password") });
  }
  get updatePasswordControl() { return this.updatePassword.controls; }
  
  getUserDetails(){
    const tokenNumber = this.route.snapshot.params.tokenNumber;
    const username = this.route.snapshot.params.username;
    //let apiUrl=APIConstant.USER_KEY_DETAIL.replace("{tokenNumber}",tokenNumber);
    let apiUrl=''
    
    this.apiserviceService.post(apiUrl,{},{}).subscribe((response)=>{      
      this.userData=response.data.dataGet;
      this.spinner.hide();
    },error=>{
      this.spinner.hide();
      this.commonService.redirectToPage(navigationConstants.LOGIN);
    });
  }

  onUpdatePasswordSubmit(){
    this.isUpdatePasswordSubmitted=true;
    if (this.updatePassword.invalid) {
      return;
    } else {
      this.btnSpinner=true;
      let updatePasswordData={
        userName: this.route.snapshot.params.username,
        userPwd: this.updatePasswordControl.password.value, 
        userPwdEncrypted: shajs('sha512').update(  this.updatePasswordControl.password.value  ).digest('hex'), 
        tokenID: this.route.snapshot.params.tokenNumber
      };
       
      this.apiserviceService.post(APIConstant.UpdateUserForgetPassword, updatePasswordData, {}).subscribe(response=>{
        this.btnSpinner=false;
        if(response.result === false) {
          this.commonService.notification(this.validationMessage.toasterClass.error, response.message );
          return
        }

        this.commonService.notification(this.validationMessage.toasterClass.success, 'Password updated successfully.');
        this.commonService.redirectToPage(navigationConstants.LOGIN);


      },error=>{
        this.btnSpinner=false;
        let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong!'
        this.commonService.notification(this.validationMessage.toasterClass.error, errMsg);
      })
    }
    
  }

  hide: boolean = true;
  hide1: boolean = true;
  readMore: boolean = true
  showtext: boolean = true


  myFunction() {
    this.hide = !this.hide;
  }
  myFunction1() {
    this.hide1 = !this.hide1;
  }

  showText() {
    this.readMore = !this.readMore
    this.showtext = !this.showtext
}
}
