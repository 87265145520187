<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important">
    <div class="modal-dialog custom-alert modal-dialog-centered" role="document">
        <div class="modal-content" style="background: none !important; border:0; outline: none; border-radius:11px;">
            <div *ngIf="message?.type == 'confirm'" class="modal-body {{message.bgClass}}">
                <div class="row">
                    <div class="col-md-12">
                        <h5 class="text-center">{{message.text}}</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="confirm-button">
                            <a (click)="message.siFn()">
                                <button class="btn {{message.bgClass == 'bgWhite' ? 'btn-red' : 'btn-success'}}  widthAuto">Yes</button>
                            </a>&nbsp;
                            <a (click)="message.noFn()">
                                <button class="btn btn-danger">No</button>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>