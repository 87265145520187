import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {
  private baseUrl = environment.backendUrl+'api/';

  constructor(
    private http: HttpClient
  ) { }

  get(url:string,params:any){
    return this.http.get<any>(this.baseUrl+url,{params: params}).pipe(
      catchError(this.errorHandl)
    );
  }
  post(url:string,payload:any,params:any){
    return this.http.post<any>(this.baseUrl+url,payload,{params: params}).pipe(
      catchError(this.errorHandl)
    );
  }
  postFile(url:string,payload:any,params:any){
    const options={
      params: params,
      // headers:{'Accept':'application/json'}
      // headers:{'Content-Type':'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)}
    }
    return this.http.post<any>(this.baseUrl+url,payload,options).pipe(
      catchError(this.errorHandl)
    );
  }
  delete(url:string,params:any){
    return this.http.delete<any>(this.baseUrl+url,{params: params}).pipe(
      catchError(this.errorHandl)
    );
  }
  put(url:string,params:any){
    return this.http.put<any>(this.baseUrl+url,{params: params}).pipe(
      catchError(this.errorHandl)
    );
  }
  third_party_post(url:string,payload:any,params:any){
    return this.http.post<any>(url,payload,{params: params}).pipe( 
      catchError(this.errorHandl)
    );
  }
  third_party_get(url:string,params:any){ 
    return this.http.get<any>(url,{params: params}).pipe( 
      catchError(this.errorHandl)
    );
  }

  errorHandl(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);  //Rethrow it back to component
 }
 getExt(url:string,params:any){
  return this.http.get<any>(url,{params: params}).pipe(
    catchError(this.errorHandl)
  );
}

}
