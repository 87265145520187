import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  ArcLocalToken = window.localStorage.getItem('ArcLocalToken')
  ArcUser = window.localStorage.getItem('ArcUser') 
  isUrlAllowed = false
   
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      this.ArcLocalToken = window.localStorage.getItem('ArcLocalToken')
      this.ArcUser = window.localStorage.getItem('ArcUser') 

      if(this.ArcLocalToken != null && this.ArcUser != null) {
        sessionStorage.setItem('token', this.ArcLocalToken)
        sessionStorage.setItem('user', this.ArcUser) 

        
        var allowedUrls = JSON.parse(this.ArcUser).allowedUrls
        var urlSegments = state.url.split('/') 
        var urlSegFirst2 = urlSegments.length > 2 ?
                           urlSegments[1] == 'customer-requests'? 'customer-requests' :
                           urlSegments[1]+'/'+urlSegments[2] : urlSegments[1]

        this.isUrlAllowed = allowedUrls.find((i)=> i === urlSegFirst2 )
        if(state.url == '/alerts-notifications/notification-list' || state.url == '/user/change-password' || state.url.includes('learning-center') || state.url.includes('schedule-task')) {
          this.isUrlAllowed = true
        }

        //console.log(urlSegFirst2, this.isUrlAllowed)

        if(JSON.parse(this.ArcUser).userCompanyInfo[0].userType == 'Arcadia Staff' &&
        (
          (
          state.url.includes('new-order') ||
          state.url.includes('new-inbound-order') ||
          state.url.includes('add') ||
          state.url.includes('edit')
          ) && !state.url.includes('view')
        )
        ){ 
          this.router.navigate(['dashboard'])
        }

        if( !this.isUrlAllowed ){ 
          this.router.navigate(['dashboard'])
        }

        return true;
      } else {
        if(state.url != '/login' && state.url != '/dashboard' ){
          localStorage.setItem('preLoginUrl', state.url)
        }
        sessionStorage.removeItem('token') 
        sessionStorage.removeItem('user')
        this.router.navigate(['login'])
      }  
  }
  
}
