<!-- Left Menu -->
<div class="left-menu" *ngIf="showSideMenu">
    <div class="me-3">
        <button (click)="toggleClassOnBody()" class="navbar-toggler navbar-toggler align-self-center" type="button"
            data-bs-toggle="minimize">
            <i class="fas fa-arrow-left"></i>
            <i class="fas fa-arrow-right"></i>
        </button>
    </div>
    <a [ngClass]="{'active-left-menu two-lines': slug.includes('/'+menuItem.reportURL.substring( menuItem.reportURL.lastIndexOf('/') + 1).trim()) ,  'two-lines': !slug.includes(menuItem.reportURL.substring( menuItem.reportURL.lastIndexOf('/') + 1).trim()) }"
        *ngFor="let menuItem of sideMenu[0].reportMenu; let i = index" routerLink="/{{menuItem.reportURL}}"><i
            class="{{menuItem.reportImageName}}"></i>
        <div class="linkTxt">{{ menuItem.reporttitlename }}</div>
    </a>
</div>
<!-- Left Menu -->