import { Component, OnInit } from '@angular/core'; 
import { NgxSpinnerService } from "ngx-spinner"; 
import { Router } from '@angular/router';  
import { CommonService } from "src/app/services/common.service";
import { ApiserviceService } from "src/app/services/apiservice.service"; 
import { APIConstant } from "src/app/constants/apiConstants"; 
import { validationMessage } from "src/app/constants/validationMessage"; 
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
}) 

export class AppointmentComponent implements OnInit {
  validationMessage=validationMessage;
  AMSBaseUrl:string=''
  userData:any=[]
  selectedAccounts:string=''
  

  constructor(
    private commonService: CommonService,
    private apiserviceService: ApiserviceService,  
    private spinner: NgxSpinnerService, 
    public router:Router,
    public titleService:Title
  ) { 
    this.userData = JSON.parse(localStorage.getItem('ArcUser'));
    console.log(this.userData)
  }

  ngOnInit(): void {
    this.getAMSBaseUrl()
    
    this.selectedAccounts = this.userData.allAccounts?.filter((acc)=>acc.checked).map((item)=>{
      return item.cust_id+'@'+item.wh_name
    }).join('~') 
    this.titleService.setTitle(validationMessage.common.heading +"Appointment")
  }

  getAMSBaseUrl(){
    let payload = {
      VariableName: 'AppointmentManagementSystemURL'
    } 
    this.spinner.show('dataLoader') 
    this.apiserviceService.get(APIConstant.GetGlobalConfigValue,payload).subscribe((response)=>{
      if(response.result){ 
        this.AMSBaseUrl = response.data[0].globalVariableValue  
      } 
      this.spinner.hide('dataLoader'); 
    }, error =>{
      this.spinner.hide('dataLoader')
      let errMsg =  (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong!'
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg);
    })
  } 

  onNavigate(){  

    let payload = {
      inputParameters: this.userData.username
    } 
    this.spinner.show('dataLoader') 
    this.apiserviceService.get(APIConstant.GetERPCLientIdFromUserName,payload).subscribe((response)=>{
      if(response.result){ 
        const url = response.data 
        console.log(response.data)
        window.open(url, '_blank');
      } 
      this.spinner.hide('dataLoader'); 
    }, error =>{
      this.spinner.hide('dataLoader')
      let errMsg =  (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong!'
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg);
    })





    //const url = 'https://arc-aztms-pr1.jdadelivers.com/tm/framework/Frame.jsp';
    // const url = this.AMSBaseUrl+'/?UserID='+this.userData.username+'&Accounts='+this.selectedAccounts
    // window.open(url, '_blank');
}
}
