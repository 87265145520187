import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { DirectivesModule } from "src/app/directives/directives.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE, } from '@angular/material/core';
import { OwlDateTimeModule, OwlMomentDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS , OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from '@danielmoncada/angular-datetime-picker';
import { NgxSpinnerModule } from "ngx-spinner";
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { SideMenuComponent } from 'src/app/shared/side-menu/side-menu.component';
import { ReportFiltersComponent } from 'src/app/shared/report-filters/report-filters.component';
import { ReportFilters1Component } from 'src/app/shared/report-filters/report-filters1.component';
import { DynamicFormBuilderModule } from 'src/app/shared/report-filters/dynamic-form-builder/dynamic-form-builder.module' 
import { NoSanitizePipe, NumberWithCommasPipe } from 'src/app/services/nosanitizerpipe'
import { DragDropModule } from '@angular/cdk/drag-drop';
 
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'MM YYYY',
  },
};

import { Inject, Injectable, Optional } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';

@Injectable()
export class MomentUtcDateAdapter extends MomentDateAdapter {

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
  }

  createDate(year: number, month: number, date: number): Moment {
    // Moment.js will create an invalid date if any of the components are out of bounds, but we
    // explicitly check each case so we can throw more descriptive errors.
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    let result = moment.utc({ year, month, date }).locale(this.locale);

    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }
}


export const MY_MOMENT_FORMATS = {
  parseInput: 'MM/DD/YYYY',
  fullPickerInput: 'MM/DD/YYYY',
  datePickerInput: 'MM/DD/YYYY',
  timePickerInput: 'HH:mm:ss',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY' 
};
  

export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    NgSelectModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormBuilderModule,
    OwlDateTimeModule, OwlNativeDateTimeModule, OwlMomentDateTimeModule, DragDropModule, MatTableModule, MatIconModule, MatSortModule 
  ],
  exports: [ 
    SideMenuComponent,
    ReportFiltersComponent,
    ReportFilters1Component,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    NgSelectModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [ 
    SideMenuComponent,
    ReportFiltersComponent,
    ReportFilters1Component,
    NoSanitizePipe,
    NumberWithCommasPipe
  ],
  providers: [
    // { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    // { provide: DateAdapter, useClass: MomentUtcDateAdapter }
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: false } },
   
],
schemas: [CUSTOM_ELEMENTS_SCHEMA] 
})
export class SharedModule { }
