import { Directive, HostListener, Input } from '@angular/core';
import { regExConstant } from 'src/app/constants/regExConstant';

@Directive({
selector: '[signedNumbers]'
})
export class signedNumbersDirective {

@Input() isAlphaNumeric: boolean;

@HostListener('keypress', ['$event']) onKeyPress(event) {
return new RegExp(regExConstant.signedNumbers).test(event.key);
}

}