<!-- Middle Part -->
<div class="middle-part" *ngIf="!isReportVisible">
    <div class="clear"></div>
    <div class="container-fluid p-0">
        <!-- -- Reports start -- -->
        <div class="col-md-12 mb-4">
            <div class="row">
                <!-- first block start -->
                <div class="col-md-12 mt-3">
                    
                    <div class="white-content-area pb-3">
                        <div class="col-md-12">
                            <div class="col-md-8">
                                <h2 class="text-left fw-bold">Business Intelligence</h2>
                            </div>
                            <ngx-spinner name="dataLoader" [fullScreen]="false" type="timer" size="medium"></ngx-spinner>

                            <div class="col-md-12">

                                <mat-accordion>
                                    <mat-expansion-panel [expanded]="isFirst">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title> Inbound Outbound WMS data </mat-panel-title>
                                      </mat-expansion-panel-header>
                                      <a class="mt-4" style="color:#00a1e1"
                                         href="javascript:void();" (click)="ReportVisible()">
                                         https://prod-useast-b.online.tableau.com/#/site/arcadiacold/views/UpcomingAppointments-WMS/AppointmentDetailData-Dashboard</a>
                                    </mat-expansion-panel>


                                    <mat-expansion-panel [expanded]="isFirst">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title> AMS report </mat-panel-title>
                                      </mat-expansion-panel-header>
                                        <div>
                                            <a  class="mt-4" style="color:#00a1e1"
                                            href="javascript:void();" (click)="ReportVisibleAMS1()">
                                            https://prod-useast-b.online.tableau.com/#/site/arcadiacold/views/UpcomingAppointments-WMS/DashboardInformation?:iid=4
                                            </a>
                                        </div>
                                      
                                        <div>
                                            <a  class="mt-4" style="color:#00a1e1"
                                            href="javascript:void();" (click)="ReportVisibleAMS2()">
                                            https://prod-useast-b.online.tableau.com/#/site/arcadiacold/views/UpcomingAppointments-WMS/AppointmentDetailData-Dashboard
                                            </a>
                                        </div>
                                      
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isInboundOutboundReportVisible">
    <tableau-viz id="tableau-viz" src="https://prod-useast-b.online.tableau.com/#/site/arcadiacold/views/UpcomingAppointments-WMS/AppointmentDetailData-Dashboard"
     token={{tokenKey}}
     width="100%"
     height="1004"
     hide-tabs
     toolbar="bottom">
    </tableau-viz>
</div>

<div *ngIf="isAMSReportVisible1">
    <tableau-viz id="tableau-viz" src="https://prod-useast-b.online.tableau.com/#/site/arcadiacold/views/UpcomingAppointments-WMS/DashboardInformation?:iid=4"
     token={{tokenKey}}
     width="100%"
     height="1004"
     hide-tabs
     toolbar="bottom">
    </tableau-viz>
</div>

<div *ngIf="isAMSReportVisible2">
    <tableau-viz id="tableau-viz" src="https://prod-useast-b.online.tableau.com/#/site/arcadiacold/views/UpcomingAppointments-WMS/AppointmentDetailData-Dashboard"
     token={{tokenKey}}
     width="100%"
     height="1004"
     hide-tabs
     toolbar="bottom">
    </tableau-viz>
</div>
