<!-- Middle Part -->
<div class="middle-part">
    <div class="clear"></div>

    <div class="container-fluid p-0">  
            
        <!-- -- Weather based start -- -->
        <div class="col-md-12">
            <ngx-spinner name="dataLoader" [fullScreen]="false" type="timer" size="medium"></ngx-spinner>
            <marquee behavior="right" class="dashboard-marquee" scrollamount="2" style="border-radius:0px;">
                <span>{{messageList !== '' ? messageList : "Welcome to the Arcadia Cold Portal! This section will highlight important news, new portal features, and our maintenance windows. Welcome to the Age of Arcadia."}}</span>
            </marquee>
            
            <div class="row">
                <div class="col-md-8">
                    <div class="row">

                        <div class="col-md-6 col-sm-6 mbm-3 mb-4">
                            <div class="white-content-area-inn box-dash pb-2">
                                <div class="col-md-12">
                                    <h2 class="sub_title">Quick Links</h2>
                                    <ul>
                                        <li *ngFor="let link of urlLinks">
                                          <a href="{{ link.linkValue }}">{{ link.linkName }}</a>
                                        </li>
                                      </ul>                                   
                                </div>
                                <!-- <div class="col-md-12 mb-3">
                                <p>Report</p>
                            </div> -->
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-6 mbm-3 mb-4" *ngIf="verbiageMessage">
                            <div class="white-content-area-inn box-dash pb-2">
                                <div class="col-md-12 centerText mt-2">
                                     <p [innerHTML]="verbiageMessage"> </p>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- first block start -->

                        <!-- second block start -->
                        <div class="col-md-6 col-sm-6 mbm-3 mb-4">
                            <div class="white-content-area-inn box-dash pb-3">
                                <div class="col-md-12 mb-2">
                                    <h2 class="sub_title">Today’s Date</h2>
                                    <p class="m-0"><strong>{{timezoneDate}}</strong></p>
                                </div>
                                <div class="col-md-12">
                                    <h2 class="sub_title">Arcadia Holiday Schedule</h2>

                                    <div *ngFor="let whList of holidayScheduleforDashboard">
                                        <ul>
                                            <li>
                                                <h5 class="mt-2">{{whList.warehouse}}</h5>
                                            </li>
                                            <ul>
                                                <li *ngFor="let holiday of whList.holidays">{{holiday.scheduleDate}}
                                                </li>
                                            </ul>
                                        </ul>
                                        
                                    </div>
                                    
                                    <div *ngIf="holidayScheduleforDashboard.length === 0">
                                        N/A
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- second block end -->
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-sm-6 mbm-3 mb-4">
                            <div class="white-content-area-inn box-dash pb-3">
                                <div class="col-md-12">
                                    <h2 class="sub_title">Latest Customer Service Requests</h2>
                                    <ul *ngIf="customerRequestforDashboard.length">
                                        <li *ngFor="let item of customerRequestforDashboard"> 
                                                <span *ngIf="userData.userCompanyInfo[0].userType != 'Arcadia Staff'">
                                                <a routerLink="/customer-requests/add-edit-customer-request/{{item.custRequestID}}">
                                                    {{item.customerRequestNumber }} {{ item.description != 'null' ? item.description : '' }}
                                                </a>
                                            </span>
                                            <span *ngIf="userData.userCompanyInfo[0].userType == 'Arcadia Staff'">
                                                <a routerLink="/customer-requests">
                                                    {{item.customerRequestNumber }} {{ item.description != 'null' ? item.description : '' }}
                                                </a>
                                            </span>
                                        </li>
                                    </ul>
                                    <p *ngIf="customerRequestforDashboard.length === 0">N/A</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 px-0 mb-4">
                    <!-- third block start -->
                    <div class="col-md-12 col-sm-12" style="height:100%;">
                        <div class="white-content-area box-dash"  style="height: inherit;">
                            <div class="col-md-12">
                                <h2 class="sub_title">Arcadia Warehouse Information</h2>
                            </div>
                            <div *ngFor="let whinfo of userSiteInfo" class="col-md-12 mb-3">
                                <h5>{{whinfo.siteCity}} </h5>
                                <h6>{{whinfo.label}}:</h6>
                                <p>Tel: {{whinfo.sitePhone}}</p>
                                <p>Email: {{whinfo.siteEmail}}</p>
                                                            </div>
                            <div *ngIf="userSiteInfo.length===0" class="col-md-12 mb-3">
                                N/A
                            </div>
                        </div>
                    </div>
                    <!-- third block end -->
                </div>
            </div>
        </div>
        <!-- third-row -->
    
        <!-- -- Weather based end -- -->
        <div class="col-md-12 mb-4">
            <!-- <p style="text-align: right;" *ngIf="userSiteInfo" [innerHTML]="userSiteInfo"></p>                 -->
        </div>
        
    </div>
    <script src="assets/js/jquery.text-marquee.js" type="text/javascript"></script>

    <script type="text/javascript">
        $(document).ready(function () {
            $('#loop').textMarquee({
                mode: 'loop'
            });
        });
    </script>
</div>
<!-- Middle Part -->