<div class="clear"></div>
    <div class="container-fluid p-0"> 
        <div class="col-md-12 mb-4">
            <div class="row"> 
                <div class="col-md-12 mt-3">
                    <div class="page404 box-dash pb-3">
                        <div class="col-md-12"> 
                            <div class="section text-center">
                                <h1 class="error404">404</h1>
                                <h3 class="title404 mb-3">Oops! Looks like you got lost</h3>
                                <a class="mt-3 mr-0 back-home btn-md submit-button" routerLink="/dashboard">Back to home</a>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>






<!-- <div class="middle-part">
   
    <div class="right-content-area">
        <div class="container">
            <div class="row">
                <div class="white-content-area">
                   

                </div>
            </div>
        </div>
    </div>
</div> -->