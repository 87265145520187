import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { PasswordValidator } from 'src/app/customvalidator/password.validator';
import { passwordNoWhitespaceValidator } from 'src/app/customvalidator/passwordNoWhitespace.validator';
import { SessionStorageService } from "src/app/services/session-storage.service";
import { CommonService } from "src/app/services/common.service";
import { ApiserviceService } from "src/app/services/apiservice.service";
import { APIConstant } from "src/app/constants/apiConstants";
import { regExConstant } from 'src/app/constants/regExConstant';
import { validationMessage } from "src/app/constants/validationMessage";
import { navigationConstants } from "src/app/constants/navigationConstant";
import { customStorage } from "src/app/constants/storageKeys";
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { environment } from 'src/environments/environment';
const shajs = require('sha.js');
import * as _ from 'lodash'
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  environment = environment
  validationMessage = validationMessage;
  closeResult: string;
  loginForm: FormGroup;
  forgetPassword: FormGroup;
  resetPasswordForm: FormGroup;
  isLoginFormSubmitted = false;
  isForgetPasswordSubmitted = false;
  isResetPasswordSubmitted = false;
  btnSpinner: boolean = false;
  btnPasswordSpinner: boolean = false;
  showForm = true;
  timedOut = false;
  attempts: any;
  pwd: any;
  randomLoginInit: string = '';
  moduleForm: FormGroup;
  formEditable: boolean = true;
  isModuleFormSubmitted = false;
  bodyBgImg: string;
  readMore: boolean = true
  showtext: boolean = true
  tempLoginRes: any;
  acceptTnC: boolean = false;
  btnAccountSelectionDisabled: boolean = true;
  noAccountSelectedFlag: boolean = false;
  anyAccountSelect: boolean = false;
  ipAddress: string = '14.97.189.106';
  allowedUrls: any = [];
  termsAndConditions: string = ''
  uatInstance: boolean = false

  @ViewChild('MultipleAccountSelectionModal') MultipleAccountSelectionModal: any;
  @ViewChild('TermsAndConditionsModal') TermsAndConditionsModal: any;
  @ViewChild('ResetPasswordFormModal') ResetPasswordFormModal: any;

  constructor(
    private commonService: CommonService,
    private apiserviceService: ApiserviceService,
    private formBuilder: FormBuilder,
    private sessionStorageService: SessionStorageService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    public router: Router,
    private toastr: ToastrService,
    public titleService:Title
  ) {
    let token = this.commonService.getToken();
    if (token != null && token != '' && token != undefined) {
      this.commonService.redirectToPage(navigationConstants.DASHBOARD);
      // this.router.navigate([navigationConstants.DASHBOARD])
      //   .then(() => {
      //     window.location.reload();
      // });
    }

    let bgm = ['assets/images/bg01.jpg']
    this.bodyBgImg = bgm[Math.floor(Math.random() * bgm.length)];
  }

  ngOnInit(): void {
    if(environment.frontendUrl == 'https://testportal.arcadiaone.com/') {
      this.uatInstance = true
    }
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);

    this.loginForm = this.formBuilder.group({
      username: ["", Validators.compose([Validators.required, Validators.maxLength(45)])],
      password: ["", Validators.compose([Validators.required, Validators.maxLength(30)])],
    });
    this.forgetPassword = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(regExConstant.email)]]
    });

    this.resetPasswordForm = this.formBuilder.group({
      username: [""],
      newpassword: ["", [Validators.required, passwordNoWhitespaceValidator.cannotContainSpace, Validators.minLength(12), Validators.maxLength(30), Validators.pattern(regExConstant.passwordValidators)]],
      confirmpassword: ["", Validators.required]
    }, { validator: PasswordValidator("newpassword", "confirmpassword") });
  }
  get loginFormControl() { return this.loginForm.controls; }
  get forgetPasswordControl() { return this.forgetPassword.controls; }
  get resetPasswordControl() { return this.resetPasswordForm.controls; }

  openForgetPasswordModal(modelContent) {
    this.modalService.open(modelContent, {
      size: "md modal-dialog-centered",
      backdrop: 'static',
      windowClass: "modalClass-700",
      keyboard: false,
      ariaLabelledBy: "modal-basic-title"
    }).result.then(result => {
      this.closeResult = `Closed with: ${result}`;
      this.resetForgetPassword();
    }, reason => {
      this.closeResult = `Dismissed`;
      this.resetForgetPassword();
    }
    );
  }
  clearForm() {
    this.loginForm.get('username').reset();
    this.loginForm.get('password').reset();
  }

  openTnCModal(modelContent) {
    this.modalService.open(modelContent, {
      size: "xl",
      backdrop: 'static',
      windowClass: "modalClass-700",
      keyboard: false,
      ariaLabelledBy: "modal-basic-title"
    }).result.then(result => {
      this.closeResult = `Closed with: ${result}`;
    }, reason => {
      this.closeResult = `Dismissed`;
    }
    );
    this.GetTermAndConditions()
  }

  onLoginFormSubmit(param = null) {
    this.toastr.clear();
    this.isLoginFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    let loginData = {
      username: this.loginFormControl.username.value,
      password: shajs('sha512').update(this.loginFormControl.password.value).digest('hex'),
      IPAddress: this.ipAddress
    };

    this.apiserviceService.post(APIConstant.LOGIN, loginData, {}).subscribe(response => {
      if (response.result === false) {
        this.commonService.logout();
        this.commonService.notification(this.validationMessage.toasterClass.error, response.message);
        return;
      }
      this.tempLoginRes = response;
      // console.log(this.tempLoginRes);
      
      if (!response.data.userloginStatus[0].isTermsConditionChecked) {
        this.modalService.dismissAll();
        this.openTnCModal(this.TermsAndConditionsModal)
        return;
      }

      if (response.data.userloginStatus[0].isTermsConditionChecked && (response.data.userloginStatus[0].passwordModifiedDate == null || response.data.userloginStatus[0].passwordModifiedDate == '')) {
        this.modalService.dismissAll();
        this.openResetPasswordModal(this.ResetPasswordFormModal);
        return;
      }

      if (response.data.userloginAccount.length > 1) {
        this.modalService.dismissAll();
        this.openAccountSelectionPopup(this.MultipleAccountSelectionModal)
        return;
      }

      const selectedAccounts = [];
      if (response.data.userloginAccount.length === 1) {
        selectedAccounts.push(response.data.userloginAccount[0].cust_id + ' @' + response.data.userloginAccount[0].wh_name);
        response.data.userloginAccount[0].checked = true;
      }

      this.btnSpinner = false;
      this.sessionStorageService.setData(
        customStorage.sessionKeys.token,
        response.token
      );
      localStorage.setItem('ArcLocalToken', response.token)

      this.sessionStorageService.setData(
        customStorage.sessionKeys.user,
        JSON.stringify({ allAccounts: response.data.userloginAccount, username: loginData.username, selectedAccounts: selectedAccounts, userCompanyInfo: response.data.userCompanyInfo })
      );
      localStorage.setItem('ArcUser', JSON.stringify({ allAccounts: response.data.userloginAccount, username: loginData.username, selectedAccounts: selectedAccounts, userCompanyInfo: response.data.userCompanyInfo }))

      this.getAndSetMenu();
    }, error => {
      this.btnSpinner = false;
      this.clearForm();
    });

  }

  acceptTermsAndConditions() {
    this.sessionStorageService.setData(
      customStorage.sessionKeys.token,
      this.tempLoginRes.token
    );

    localStorage.setItem('ArcLocalToken', this.tempLoginRes.token)
    let params = {
      username: this.loginFormControl.username.value,
    };

    this.apiserviceService.post(APIConstant.UpdAcceptTermsTimestamp, params, {}).subscribe(response => {
      if (response.result === false) {
        this.commonService.logout();
        this.commonService.notification(this.validationMessage.toasterClass.error, response.message);
        return;
      }
      if (!this.tempLoginRes.data.userloginStatus[0].isTermsConditionChecked) {
        this.modalService.dismissAll();
        this.openResetPasswordModal(this.ResetPasswordFormModal);
        return;
      }

      // if(this.tempLoginRes.data.userloginAccount.length > 1 ){
      //   this.modalService.dismissAll();
      //   this.openAccountSelectionPopup(this.MultipleAccountSelectionModal); 
      //   return;
      // } 

      // this.sessionStorageService.setData(
      //   customStorage.sessionKeys.token,
      //   this.tempLoginRes.token
      // ); 
      // localStorage.setItem('ArcLocalToken',  this.tempLoginRes.token )

      // const selectedAccounts = []; 
      // if(this.tempLoginRes.data.userloginAccount.length === 1){
      //   selectedAccounts.push(this.tempLoginRes.data.userloginAccount[0].cust_id +' @'+ this.tempLoginRes.data.userloginAccount[0].wh_name ); 
      //   this.tempLoginRes.data.userloginAccount[0].checked = true;
      // } 

      // this.sessionStorageService.setData(
      //   customStorage.sessionKeys.user,
      //   JSON.stringify({allAccounts: this.tempLoginRes.data.userloginAccount, username: params.username,selectedAccounts:selectedAccounts, userCompanyInfo:this.tempLoginRes.data.userCompanyInfo } )
      // );  
      // localStorage.setItem('ArcUser', JSON.stringify({allAccounts: this.tempLoginRes.data.userloginAccount, username: params.username,selectedAccounts:selectedAccounts, userCompanyInfo:this.tempLoginRes.data.userCompanyInfo } ))

      // this.getAndSetMenu(); 
    }, error => {
      this.commonService.logout();
      this.commonService.notification(this.validationMessage.toasterClass.error, error.error.errors[Object.keys(error.error.errors)[0]]);
    });
  }

  openAccountSelectionPopup(modelContent) {
    this.modalService.open(modelContent, {
      size: "md lg modal-dialog-centered",
      backdrop: 'static',
      windowClass: "modalClass-700",
      keyboard: false,
      ariaLabelledBy: "modal-basic-title"
    }).result.then(result => {
      this.closeResult = `Closed with: ${result}`;
    }, reason => {
      this.closeResult = `Dismissed`;
    }
    );
  }

  onForgetPasswordSubmit() {
    this.isForgetPasswordSubmitted = true;
    if (this.forgetPassword.invalid) {
      return;
    } else {
      this.btnPasswordSpinner = true;
      let forgetPasswordData = {
        userEmailAddress: this.forgetPassword.value.email
      }
      this.apiserviceService.post(APIConstant.ForgetPassword, forgetPasswordData, {}).subscribe(response => {
        if (response.data) {
          this.commonService.notification(this.validationMessage.toasterClass.error, response.data);
        } else {
          this.commonService.notification(this.validationMessage.toasterClass.success, 'Password reset link has been sent on your email');
        }
        this.btnPasswordSpinner = false;
        this.modalService.dismissAll();
        this.resetForgetPassword();
      }, error => {
        this.btnPasswordSpinner = false;
        this.commonService.notification(this.validationMessage.toasterClass.error, 'Something went wrong, try again later.');
      });
    }
  }


  resetForgetPassword() {
    this.isForgetPasswordSubmitted = false;
    this.forgetPassword.reset();
    this.forgetPassword.setErrors(null);
    this.forgetPassword.markAsPristine();
    this.forgetPassword.markAsUntouched();
    this.forgetPassword.updateValueAndValidity();
  }


  checkAllCheckBox(ev: any) {
    this.tempLoginRes.data.userloginAccount.forEach(x => x.checked = ev.target.checked)
    this.checkAndSetFlags();
  }
  isAllCheckBoxChecked() {
    return this.tempLoginRes.data.userloginAccount.every(p => p.checked);
  }

  checkAndSetFlags() {
    const isAnySelected = (this.tempLoginRes.data.userloginAccount.find(x => x.checked === true) ? true : false)
    if (isAnySelected) {
      this.anyAccountSelect = true;
      this.btnAccountSelectionDisabled = false;
    } else {
      this.anyAccountSelect = false;
      this.btnAccountSelectionDisabled = true;
    }
  }


  accountSelectionSubmit() {
    if (!this.anyAccountSelect) {
      this.commonService.notification(this.validationMessage.toasterClass.error, 'You have to select atleast one account');
      return;
    }

    // const selectedAccounts = (this.tempLoginRes.data.userloginAccount.filter(x => x.checked === true).map(function(x){
    //   return x.cust_id+ '@'+ x.wh_name;
    // }));  
    const selectedAccountsTemp = (this.tempLoginRes.data.userloginAccount.filter(x => x.checked === true));
    const groupedAccounts = _.groupBy(selectedAccountsTemp, acc => acc.wh_name);

    const selectedAccounts = [];
    Object.keys(groupedAccounts).forEach(key => {
      const custIds = groupedAccounts[key].map((item) => {
        return item.cust_id;
      });
      selectedAccounts.push(custIds.join(', ') + ' @' + key);
    });

    if (selectedAccounts) {
      this.sessionStorageService.setData(
        customStorage.sessionKeys.token,
        this.tempLoginRes.token
      );
      localStorage.setItem('ArcLocalToken', this.tempLoginRes.token)

      this.sessionStorageService.setData(
        customStorage.sessionKeys.user,
        JSON.stringify({ allAccounts: this.tempLoginRes.data.userloginAccount, username: this.loginFormControl.username.value, selectedAccounts: selectedAccounts, userCompanyInfo: this.tempLoginRes.data.userCompanyInfo })
      );

      localStorage.setItem('ArcUser', JSON.stringify({ allAccounts: this.tempLoginRes.data.userloginAccount, username: this.loginFormControl.username.value, selectedAccounts: selectedAccounts, userCompanyInfo: this.tempLoginRes.data.userCompanyInfo }))

      this.getAndSetMenu();
    }
  }

  getAndSetMenu() {
    
    let params = {
      username: this.loginFormControl.username.value,
    };

    this.apiserviceService.get(APIConstant.getAndSetMenuData, params).subscribe(response => {
      const userData = JSON.parse(this.sessionStorageService.getData(customStorage.sessionKeys.user));
      userData.menu = response.data;
      console.log(response.data);
      console.log(userData.menu)

      // for(let i=0;i<10;i++){
      //   this.titleService.setTitle(userData.menu[i].menuSectionName)
      // }
      // for(let i=0;i<10;i++){
      // this.titleService.setTitle(response.data[i].menuSectionName)}
      response.data?.map((item, i) => {
        this.allowedUrls.push(item.menuURL);
        console.log("Hello",response.data)
        if (item.reportMenu != null) {
          item.reportMenu?.map((submenu) => {
            this.allowedUrls.push(submenu.reportURL);
          })
        }
      })
      userData.allowedUrls = this.allowedUrls;

      this.sessionStorageService.setData(
        customStorage.sessionKeys.user,
        JSON.stringify(userData)
      );

      localStorage.setItem('ArcUser', JSON.stringify(userData))
      this.tempLoginRes = '';
      this.modalService.dismissAll();
      this.commonService.redirectToPage(navigationConstants.DASHBOARD);
      // this.router.navigate([navigationConstants.DASHBOARD])
      //   .then(() => {
      //     window.location.reload();
      // });
    }, error => {
      this.commonService.logout();
      this.commonService.notification(this.validationMessage.toasterClass.error, error.error.errors[Object.keys(error.error.errors)[0]]);
    });
    

  }  

  GetTermAndConditions() {
    let params = { username: '' }
    this.spinner.show('dataLoader')
    this.apiserviceService.get(APIConstant.GetTermAndConditions, params).subscribe((response) => {
      if (response.result) {
        this.termsAndConditions = response.data['descriptionTable'][0].description
      }
      this.spinner.hide('dataLoader');
    }, error => {
      this.spinner.hide('dataLoader')
      let errMsg = (Object.keys(error.error.errors).length) ? error.error.errors[Object.keys(error.error.errors)[0]] : 'Something went wrong!'
      this.commonService.notification(this.validationMessage.toasterClass.error, errMsg);
    })
  }

  /* first login reset password */
  onResetPasswordSubmit() {
    this.isResetPasswordSubmitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      let formdata = this.resetPasswordForm.getRawValue();
      let username = this.loginFormControl.username.value;
      let payload = {
        UserName: username,
        UserPwd: formdata.newpassword,
        UserPwdEncrypted: shajs('sha512').update(formdata.newpassword).digest('hex'),
      }

      this.apiserviceService.get(APIConstant.ResetUserPassword, payload).subscribe(response => {
        if (response.result === false) {
          this.modalService.dismissAll();
          this.commonService.logout();
          this.commonService.notification(this.validationMessage.toasterClass.error, response.message);
          return;
        }

        if (this.tempLoginRes.data.userloginAccount.length > 1) {
          this.modalService.dismissAll();
          this.openAccountSelectionPopup(this.MultipleAccountSelectionModal);
          return;
        }

        this.modalService.dismissAll();
        this.sessionStorageService.setData(
          customStorage.sessionKeys.token,
          this.tempLoginRes.token
        );
        localStorage.setItem('ArcLocalToken', this.tempLoginRes.token)

        const selectedAccounts = [];
        if (this.tempLoginRes.data.userloginAccount.length === 1) {
          selectedAccounts.push(this.tempLoginRes.data.userloginAccount[0].cust_id + ' @' + this.tempLoginRes.data.userloginAccount[0].wh_name);
          this.tempLoginRes.data.userloginAccount[0].checked = true;
        }

        this.sessionStorageService.setData(
          customStorage.sessionKeys.user,
          JSON.stringify({ allAccounts: this.tempLoginRes.data.userloginAccount, username: username, selectedAccounts: selectedAccounts, userCompanyInfo: this.tempLoginRes.data.userCompanyInfo })
        );
        localStorage.setItem('ArcUser', JSON.stringify({ allAccounts: this.tempLoginRes.data.userloginAccount, username: username, selectedAccounts: selectedAccounts, userCompanyInfo: this.tempLoginRes.data.userCompanyInfo }))

        this.getAndSetMenu();
      }, error => {
        this.commonService.notification(this.validationMessage.toasterClass.error, 'Something went wrong, try again later.');
      });
    }
  }

  /* Reset password model open and close */
  openResetPasswordModal(modelContent) {
    this.modalService.open(modelContent, {
      size: "md lg modal-dialog-centered",
      backdrop: 'static',
      windowClass: "modalClass-700",
      keyboard: false,
      ariaLabelledBy: "modal-basic-title"
    }).result.then(result => {
      this.closeResult = `Closed with: ${result}`;
      this.resetPassword()
    }, reason => {
      this.closeResult = `Dismissed`;
      this.resetPassword()
    }
    );
  }

  /* Reset password form */
  resetPassword() {
    this.isResetPasswordSubmitted = false;
    this.resetPasswordForm.reset();
    this.resetPasswordForm.setErrors(null);
    this.resetPasswordForm.markAsPristine();
    this.resetPasswordForm.markAsUntouched();
    this.resetPasswordForm.updateValueAndValidity();
  }

  showText() {
    this.readMore = !this.readMore
    this.showtext = !this.showtext
  }
}