import { Directive, HostListener, Input } from '@angular/core';
import { regExConstant } from 'src/app/constants/regExConstant';

@Directive({
  selector: '[intergersOnly]'
})
export class IntergersOnlyDirective {
  // intergers numbers allowed in text box
  
  @Input() isAlphaNumeric: boolean;
  
  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(regExConstant.onlyNumber).test(event.key);
  }

  constructor() { }

}
