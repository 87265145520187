import { Component, Renderer2 ,ViewChild, HostListener} from '@angular/core';
import { Router,NavigationEnd, ActivatedRoute } from '@angular/router';

import { CommonService } from './services/common.service';

import { navigationConstants } from "src/app/constants/navigationConstant";
import { validationMessage } from "src/app/constants/validationMessage";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core"; 
import { HeaderComponent } from './shared/header/header.component';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { environment } from '../environments/environment';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Arcadia';
  showheader:boolean=false;
  showMenu:boolean=false;
  //showSideMenu:boolean=false;
  showFooter:boolean=false;
  mainClass:string='';
  idleState = "Not started.";
  timedOut = false; 
  userData:any;
  validationMessage=validationMessage;

  userActivity;
  checkUserInactivity: Subject<any> = new Subject();
  expireTime: number = 60000*(environment.sessionExpireInMinutes);
  mainpath:any
  urlPath:boolean = false

  @ViewChild(HeaderComponent ) child: HeaderComponent ; 

  constructor(
    private idle: Idle,
    // private bnIdle: BnNgIdleService,
    private renderer: Renderer2,
    public router:Router,
    private commonService:CommonService,
    private sessionStorageService: SessionStorageService,
    private titleService: Title,
    private route: ActivatedRoute
  ){ 
    this.userData = localStorage.getItem('ArcUser') ? JSON.parse(localStorage.getItem('ArcUser')) : '';
    router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {       
        let currentUrl=router.url;
        let currentUrlArr=currentUrl.split('/');
        this.mainpath=currentUrlArr[1];
        this.mainClass='wrapper'; 
        this.urlPath = this.mainpath.includes('learning-center')
        if(this.mainpath=='' || this.mainpath=='login' || this.mainpath=='reset-password' || this.mainpath=='register' ||  this.mainpath=='userlist' || this.mainpath.includes('learning-center')){
          this.showheader=false;
          this.showMenu=false;
          this.showFooter=false;
          //this.showSideMenu = false;
        
          this.renderer.removeClass(document.body, 'sidebar-mini');
          this.renderer.removeClass(document.body, 'layout-navbar-fixed');  
          this.renderer.addClass(document.body, 'loginBlk');             
        } else {

          //this.mainClass='wrapper'; 
          this.renderer.removeClass(document.body, 'loginBlk'); 
          this.renderer.removeClass(document.body, 'login-page');
          this.renderer.addClass(document.body, 'sidebar-mini');
          this.renderer.addClass(document.body, 'layout-navbar-fixed'); 
          

          this.showheader=true;
          this.showMenu=true;
          this.showFooter=true;
          // let token=this.commonService.getToken();
          let token= localStorage.getItem('ArcLocalToken');
          let user= localStorage.getItem('ArcUser');
          if(token==null || token==undefined || token==''){
            //this.commonService.notification(validationMessage.toasterClass.error, validationMessage.common.tokenInvalid);
            this.commonService.clearSession();
            //this.commonService.set_cookie('preLoginUrl', currentUrl ) 
            this.sessionStorageService.setData('preLoginUrl', currentUrl )
            this.commonService.redirectToPage(navigationConstants.LOGIN);
          } else {
            sessionStorage.setItem('token', token)
            sessionStorage.setItem('user', user) 
          }

          //if route not allowed to user 
          this.userData = JSON.parse(localStorage.getItem('ArcUser'));
          //console.log(  currentUrl.substring(1) , this.userData.allowedUrls)

          // if(!this.userData.allowedUrls.includes(  currentUrl.substring(1) )){
          //   this.commonService.notification(this.validationMessage.toasterClass.error, 'Something went wrong!');
          //   this.commonService.redirectToPage(navigationConstants.DASHBOARD); 
          // }  
        }
      }
    
    }); 


    this.setTimeout();
    this.checkUserInactivity.subscribe((expiredmessage) => {
      if(expiredmessage && this.userData) {
        this.commonService.logout();
        this.commonService.notificationSessionExpire(this.validationMessage.toasterClass.error, expiredmessage);
      }
    });
  }
  
  ngOnInit(): void {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(900);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => (this.idleState = "No longer idle."));
    this.idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      this.logout();
    });
    this.idle.onIdleStart.subscribe(
      () => (this.idleState = "You've gone idle!")
    );
    this.idle.onTimeoutWarning.subscribe(
      countdown =>
        (this.idleState = "You will time out in " + countdown + " seconds!")
    );
   
  }

  setTimeout() { 
    let expiredmessage = environment.sessionExpireMessage
    this.userActivity = setTimeout(() => this.checkUserInactivity.next(expiredmessage), this.expireTime);
  }

  @HostListener('window:mousemove') 
  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  refreshTimeSession() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

 
  logout(){
    this.commonService.clearSession();
    this.child.logout(); 
    this.commonService.redirectToPage(navigationConstants.LOGIN); 
  }

}
